import { defineStore } from 'pinia';
import piniaPersistedState from 'pinia-plugin-persistedstate';

interface User {
  _id: String;
  lastname: string;
  firstname: string;
  email: string;
  poste: string;
  phone: Number;
  isSuper?: boolean;
  isActive: boolean;
}

interface AuthState {
  token: string | null;
  user: User | null;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    token: null,
    user: null,
  }),
  actions: {
    setUser(user: User) {
      this.user = user;
    },
    setToken(token: string) {
      this.token = token;
    },
    logout() {
      this.token = null;
      this.user = null;
      sessionStorage.removeItem('Azerty234569NHyc');
    },
  },
  persist: {
    key: 'Azerty234569NHyc',
    storage: sessionStorage,
  },
});
