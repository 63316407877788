<template>
    <HeaderComponent/>
    <section id="Pee" class="py-[100px] bg-blue-100 min-h-screen w-screen relative flex justify-center items-center">
        <div class="container w-full lg:max-w-[1024px] bg-blue-100 lg:min-h-[700px] flex h- px-[10px] relative justify-center items-center">
            <div class="w-[95%] lg:h-[650px] flex justify-center items-center flex-col lg:flex-row relative overflow-hidden shadow-5xl bg-white">
                <div id="see" class="lg:w-6/12 top-[50px] lg:top-0 lg:left-[50px] w-full relative flex flex-row justify-center items-center flex-wrap">
                    <div class="relative w-[calc(100%*2/3)] flex justify-center items-center flex-wrap">
                        <div class="min-w-[calc(100%/2-30px)] p-[10px] relative rounded-[20px] m-[10px] lg:h-[140px] md:h-[110px] h-[90px] shadow-7xl bg-white border-white border-2">
                            <h1 class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-700">A</h1>
                            <h1 class="absolute bottom-[10px] left-[10px] right-[10px] text-xs font-semibold text-gray-700">Solution <br> title</h1>
                        </div>
                        <div class="min-w-[calc(100%/2-30px)] p-[10px] rounded-[20px] relative top-[-40px] m-[10px] lg:h-[140px] md:h-[110px] h-[90px] bg-blue-900/65 border-blue-900/55 border-2">
                            <h1 class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-100">A</h1>
                            <h1 class="absolute bottom-[10px] left-[10px] right-[10px] text-xs font-semibold text-gray-100">Solution <br> title</h1>
                        </div>
                        <div class="min-w-[calc(100%/2-30px)] p-[10px] relative rounded-[20px] m-[10px] lg:h-[140px] md:h-[110px] h-[90px] shadow-7xl bg-white border-white border-2">
                            <h1 class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-700">A</h1>
                            <h1 class="absolute bottom-[10px] left-[10px] right-[10px] text-xs font-semibold text-gray-700">Solution <br> title</h1>
                        </div>
                        <div class="min-w-[calc(100%/2-30px)] p-[10px] rounded-[20px] relative top-[-40px] m-[10px] lg:h-[140px] md:h-[110px] h-[90px] bg-transparent border-blue-900/50 border-2">
                            <h1 class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-700">A</h1>
                            <h1 class="absolute bottom-[10px] left-[10px] right-[10px] text-xs font-semibold text-gray-700">Solution <br> title</h1>
                        </div>
                        <div class="min-w-[calc(100%/2-30px)] p-[10px] relative rounded-[20px] m-[10px] lg:h-[140px] md:h-[110px] h-[90px] bg-blue-900 border-blue-900 border-2">
                            <h1 class="absolute top-[10px] right-[10px] text-sm font-bold text-white">A</h1>
                            <h1 class="absolute bottom-[10px] left-[10px] right-[10px] text-xs font-semibold text-white">Solution <br> title</h1>
                        </div>
                        <div class="min-w-[calc(100%/2-30px)] p-[10px] rounded-[20px] relative top-[-40px] m-[10px] lg:h-[140px] md:h-[110px] h-[90px] shadow-7xl bg-orange-500 border-orange-500 border-2">
                            <h1 class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-700">A</h1>
                            <h1 class="absolute bottom-[10px] left-[10px] right-[10px] text-xs font-semibold text-gray-700">Solution <br> title</h1>
                        </div>
                    </div>
                    <div class="relative w-[calc(100%*1/3)] flex justify-center items-center flex-wrap">
                        <div class="min-w-[calc(100%-25px)] p-[10px] relative rounded-[20px] m-[10px] lg:h-[140px] md:h-[110px] h-[90px] shadow-7xl bg-white border-white border-2">
                            <h1 class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-700">A</h1>
                            <h1 class="absolute bottom-[10px] left-[10px] right-[10px] text-xs font-semibold text-gray-700">Solution <br> title</h1>
                        </div>
                    </div>
                </div>
                <div class="lg:w-6/12 w-full flex justify-center items-center flex-col">
                    <h1 class="text-blue-200 text-[200px] font-bold">01</h1>
                    <div class="bg-white relative top-[-120px] px-[20px ] md:max-w-[400px] max-w-[300px] lg:max-w-[300px]">
                        <h1 class="text-[#014AA9] text-4xl font-bold">Solutions.</h1>
                        <p class="my-[25px] text-sm text-gray-700">
                            En collaborant 
                            avec nous, vous contribuez à façonner des innovations locales ayant un impact global. 
                            Ensemble, faisons avancer l'Afrique et inspirons le monde. Contactez-nous pour construire 
                            l’avenir dès aujourd’hui.
                        </p>
                        <button @click="goToPartenariatPage()" class="text-white bg-[#014AA9] rounded-[5px] px-[20px] py-[10px] text-sm font-semibold">
                            Devenir partenaire
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { useRouter } from "vue-router"

const router = useRouter()

function goToPartenariatPage() {
    router.push("/partenaires")
}
</script>

<style lang="css" scoped>
#see::before {
    content: "";
    width: 500px;
    height: 500px;
    background-color: rgb(219 234 254);
    position: absolute;
    left: -60px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    border-right: 2px dashed rgb(11, 114, 250);
}
#see::after {
    content: "";
    width: 35px;
    height: 35px;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 10px;
    border: 2px solid orange;
    border-radius: 10px;
    transform: rotate(45deg);
}
#Pee::after {
    content: "";
    width: 80px;
    height: 80px;
    background-color: transparent;
    position: absolute;
    left: 105px;
    top: 300px;
    border: 2px solid white;
    border-radius: 10px;
    z-index: 1000;
}
@media screen and (max-width: 768px) {
    #see::before {
        content: "";
        width: 300px;
        height: 300px;
        background-color: rgb(219 234 254);
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        border-right: 2px dashed rgb(11, 114, 250);
    }
    #Pee::after {
        content: "";
        width: 50px;
        height: 50px;
        background-color: transparent;
        position: absolute;
        left: 5px;
        top: 250px;
        border: 2px solid white;
        border-radius: 10px;
        z-index: 1000;
    }
}
</style>