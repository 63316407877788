<template>
    <div class="fixed top-[20px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
        <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
            {{ errorMessage }}
        </div>
        <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
            {{ successMessage }}
        </div>
    </div>
    <section class="w-full degrade1 h-screen overflow-hidden z-0 relative flex justify-center md:p-[20px] p-[10px] items-center">
        <div class="container degrade2 max-w-[924px] h-full md:max-h-[550px] relative rounded-[10px] shadow-4xl flex flex-col md:flex-row justify-center items-center">
            <div class="md:w-6/12 relative z-10 w-full h-full md:flex hidden">
                <img src="../assets/img/Vaybe/principal5.jpg" alt="" class="w-full h-full object-cover">
                <div class="bottom-[10px] p-[20px] absolute flex w-full justify-between items-center">
                    <div class="flex items-center">
                        <div class="w-[30px] h-[30px] min-w-[30px] rounded-[50%] mr-[5px] flex items-center justify-center relative overflow-hidden">
                            <img src="../assets/img/Logo-vaybe.png" alt="" class="w-full h-full absolute object-cover">
                        </div>
                        <div class="flex flex-col">
                            <h1 class="text-xs font-bold text-white">AGOSSA Mickaël</h1>
                            <p class="text-[10px] text-gray-100 font-semibold">Développeur Full Stack & IA</p>
                        </div>
                    </div>
                    <div class="flex items-center relative right-[30px]">
                        <a href="https://www.facebook.com/share/19yaSHFbGx/?mibextid=wwXIfr" target="_blank" class="ml-[10px]"><i class="fa-brands fa-facebook text-white text-sm"></i></a>
                        <a href="https://www.linkedin.com/company/vaybe/" target="_blank" class="ml-[10px]"><i class="fa-brands fa-linkedin text-white text-sm"></i></a>
                        <a href="https://www.instagram.com/vaybe.tech/profilecard/?igsh=emd4NGlrcnc5azFn" target="_blank" class="ml-[10px]"><i class="fa-brands fa-instagram text-white text-sm"></i></a>
                    </div>
                </div>
            </div>
            <div class="md:w-6/12 w-full lg:h-full h-full bg-gray-100 overflow-y-auto parent rounded-l-[10px] md:rounded-l-none rounded-r-[10px] md:px-[50px] p-[25px] flex flex-col justify-center items-center">
                <h1 class="font-bold text-center text-gray-800">
                    Salut 🤗, Bienvenue sur notre plateforme !
                </h1>
                <p class="text-xs text-center my-[15px] font-semibold text-gray-500">
                    Vous avez déjà un compte ? <button @click="goToLoginPage()" class="text-[#014AA9] hover:underline"> Connectez vous </button>
                </p>
                <form @submit.prevent="" class="my-[10px] w-full relative">
                    <div class="w-full relative md:flex justify-between items-center">
                        <div class="md:w-[48%] w-full relative mt-[15px]">
                            <input type="text" required v-model="prenoms" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500" placeholder="Prénoms">
                        </div>
                        <div class="md:w-[48%] w-full relative mt-[15px]">
                            <input type="text" required v-model="nom" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500" placeholder="Nom">
                        </div>
                    </div>
                    <div class="w-full relative my-[15px]">
                        <input type="text" required v-model="email" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500" placeholder="Email">
                    </div>
                    <div class="w-full relative my-[15px]">
                        <input type="password" required v-model="password" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500" placeholder="Mot de passe">
                    </div>
                    <div class="w-full relative my-[15px]">
                        <input type="password" required v-model="Cpassword" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500" placeholder="Confirmez le Mot de passe">
                    </div>
                    <div class="w-full relative my-[15px]">
                        <button type="submit" disabled class="w-full p-[10px] bg-[#014AA9] hover:bg-blue-600 flex justify-center items-center h-[45px] duration-200 text-center font-bold rounded-[5px] text-sm text-white">
                            <div v-if="isLoading" class="three-body1">
                                <div class="three-body__dot"></div>
                                <div class="three-body__dot"></div>
                                <div class="three-body__dot"></div>
                            </div>
                            <span v-else>S'inscrire</span>
                        </button>
                    </div>
                    <div class="my-[10px] relative w-full flex justify-end">
                        <p class="text-[10px] w-full mt-[15px] font-semibold text-gray-500">
                            En utilisant notre plateforme, vous acceptez nos <button class="text-[#014AA9] hover:underline"> Conditions d'utilisation </button> et consentez à notre <button class="text-blue-500 hover:underline"> Politique de confidentialité </button>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/auth';
import { onMounted, ref, computed } from "vue"
import { useRouter } from 'vue-router';
import axios from 'axios'

const email = ref("")
const password = ref("")
const nom = ref("")
const prenoms = ref("")
const Cpassword = ref("")
const errorMessage = ref("")
const successMessage = ref("")
const isLoading = ref(false)
const router = useRouter()
const authStore = useAuthStore();
const user = computed(() => authStore.user);

function goToLoginPage() {
    router.push("/login")
}

const register = async() => {
    if ( password.value !== Cpassword.value ) {
        errorMessage.value = 'Les mots de passe ne correspondent'
        hideErrorMessageAfterDelay()
        return
    }
    try {
        isLoading.value = true
        const response = await axios.post('/api/register/', {
            email: email.value,
            firstname: prenoms.value,
            lastname: nom.value,
            password: password.value
        })
        router.push({ path: '/verify', query: { email: email.value } });
    } catch {
        errorMessage.value = "Erreur lors de l'enregistrement de l'utilisateur."
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    if (user.value) {
        router.push('/dashboard')
    }
})

function goToHomePage() {
    router.push("/")
}

/* onMounted(() => {
    router.push("/partenaires")
}) */
</script>

<style scoped>
.clipy {
    clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
}
</style>