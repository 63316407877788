<template>
    <HeaderComponent/>
    <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
        <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
            {{ errorMessage }}
        </div>
        <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
            {{ successMessage }}
        </div>
    </div>
    <section class="mt-[100px] flex justify-center items-center pt-[20px] lg:pt-[50px] py-[50px] bg-white">
        <div class="container lg:max-w-[1024px] px-[20px] w-full relative flex justify-center items-center flex-col">
            <div class="w-full relative flex flex-col-reverse md:flex-row justify-center items-start">
                <div class="w-full max-w-[800px] overflow-x-hidden py-[40px] pb-[10px] rounded-[10px] lg:rounded-none relative shadow-6xl px-[20px]">
                    <div class="absolute hidden md:flex md:h-[8px] h-[4px] w-full top-0 left-0 right-0 bg-blue-700"></div>
                    <form @submit.prevent="sendMessage()">
                        <div class="py-[10px] w-full relative flex justify-between items-center">
                            <div class="w-[48%] relative">
                                <label for="prenoms" class="text-sm font-bold mb-[10px]">Prénoms <span class="text-red-700">*</span></label>
                                <input type="text" id="prenoms" required v-model="prenoms" class="w-full border border-gray-200 rounded-[5px] p-[10px] text-sm outline-none" placeholder="Prénoms">
                            </div>
                            <div class="w-[48%] relative">
                                <label for="nom" class="text-sm font-bold mb-[10px]">Nom <span class="text-red-700">*</span></label>
                                <input type="text" id="nom" required v-model="nom" class="w-full border border-gray-200 rounded-[5px] p-[10px] text-sm outline-none" placeholder="Nom">
                            </div>
                        </div>
                        <div class="py-[10px] w-full relative">
                            <label for="email" class="text-sm font-bold mb-[10px]">Email <span class="text-red-700">*</span></label>
                            <input type="text" id="email" required v-model="email" class="w-full border border-gray-200 rounded-[5px] p-[10px] text-sm outline-none" placeholder="Email">
                        </div>
                        <div class="py-[10px] w-full relative">
                            <label for="phone" class="text-sm font-bold mb-[10px]">Numéro <span class="text-red-700">*</span></label>
                            <input type="text" id="phone" required v-model="numero" class="w-full border border-gray-200 rounded-[5px] p-[10px] text-sm outline-none" placeholder="Numéro">
                        </div>
                        <div class="py-[10px] w-full relative">
                            <label for="message" class="text-sm font-bold mb-[10px]">Comment pouvons nous vous aider ? <span class="text-red-700">*</span></label>
                            <textarea id="message" required v-model="content" class="w-full border md:h-[150px] h-[100px] resize-none border-gray-200 rounded-[5px] p-[10px] text-sm outline-none" placeholder="Message"></textarea>
                        </div>
                        <div class="py-[10px] w-full relative flex justify-center items-center">
                            <button type="submit" class="bg-[#014AA9] h-[45px] flex items-center justify-center relative w-full p-[10px] rounded-[5px] cursor-pointer duration-300 hover:bg-blue-900 ease-in text-sm font-bold text-white">
                                <div v-if="isLoading" class="three-body1">
                                    <div class="three-body__dot"></div>
                                    <div class="three-body__dot"></div>
                                    <div class="three-body__dot"></div>
                                </div>
                                <span v-else>Envoyer</span>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="w-full relative md:pl-[50px] py-[25px] lg:pl-[120px]">
                    <h1 class="font-bold text-sm text-blue-500 uppercase">Contactez nous</h1>
                    <span class="text-2xl font-semibold my-[15px]">Comment pouvous nous vous aider ?</span>
                    <p class="text-sm my-[10px] text-gray-500">
                        Pour apprendre plus sur les services de Vaybe ou tout autre préoccupation, 
                        veuillez prendre contact et un membre de l'équipe vous répondra sous peu.
                    </p>
                </div>
            </div>
            <div class="w-full relative p-[20px] rounded-[20px] shadow-7xl my-[50px] flex justify-center items-center flex-wrap">
                <div class="lg:w-[calc(100%/3-20px)] md:w-[calc(50%-20px)] w-full flex cursor-pointer flex-col m-[10px] justify-start items-center ">
                    <i class="mb-[5px] fa-solid fa-location-dot text-green-600"></i>
                    <h1 class="my-[5px] font-bold text-sm">Localisation</h1>
                    <span class="text-gray-500 text-sm text-center">Abomey Calavi, Secteur 6 </span>
                </div>
                <div class="lg:w-[calc(100%/3-20px)] md:w-[calc(50%-20px)] w-full flex cursor-pointer flex-col m-[10px] justify-start items-center ">
                    <i class="mb-[5px] fa-solid fa-envelope text-blue-600"></i>
                    <h1 class="my-[5px] font-bold text-sm">Email</h1>
                    <span class="text-gray-500 text-sm text-center">contact@vaybe.tech</span>
                </div>
                <div class="lg:w-[calc(100%/3-20px)] md:w-[calc(50%-20px)] w-full flex cursor-pointer flex-col m-[10px] justify-start items-center ">
                    <i class="mb-[5px] fa-solid fa-phone text-blue-600"></i>
                    <h1 class="my-[5px] font-bold text-sm">Téléphone</h1>
                    <span class="text-gray-500 text-sm text-center">(229) 0141787700</span>
                </div>
            </div>
        </div>
    </section>
  <FooterComponent/>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { ref } from 'vue'
import axios from 'axios'

const prenoms = ref("")
const nom = ref("")
const numero = ref("")
const email = ref("")
const content = ref("")
const isLoading = ref(false)
const successMessage = ref('')
const errorMessage = ref('')

const sendMessage = async() => {
    try {
        isLoading.value = true
        await axios.post('/api/messages/send', {
            prenoms: prenoms.value,
            nom: nom.value,
            content: content.value,
            numero: numero.value,
            email: email.value
        })
        successMessage.value = "Message envoyé avec succès !"
        hideSuccessMessageAfterDelay()
        nom.value = ""
        prenoms.value = ""
        numero.value = ""
        email.value = ""
        content.value = ""
    } catch {
        errorMessage.value = "Erreur lors de l'envoie du message."
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

</script>

<style lang="css" scoped>


</style>