import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '../assets/img/Vaybe/principal5.jpg'
import _imports_1 from '../assets/img/Logo-vaybe.png'


const _hoisted_1 = { class: "fixed top-[20px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]"
}
const _hoisted_3 = {
  key: 1,
  class: "text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]"
}
const _hoisted_4 = { class: "w-full degrade1 h-screen overflow-hidden z-0 relative flex justify-center md:p-[20px] p-[10px] items-center" }
const _hoisted_5 = { class: "container degrade2 max-w-[924px] h-full md:max-h-[550px] relative rounded-[10px] shadow-4xl flex flex-col md:flex-row justify-center items-center" }
const _hoisted_6 = { class: "md:w-6/12 w-full h-full bg-gray-100 rounded-l-[10px] overflow-y-auto md:rounded-l-none rounded-r-[10px] md:p-[50px] p-[25px] flex flex-col justify-center items-center" }
const _hoisted_7 = { class: "text-xs text-center my-[15px] font-semibold text-gray-500" }
const _hoisted_8 = { class: "w-full relative my-[15px]" }
const _hoisted_9 = { class: "w-full relative my-[15px]" }
const _hoisted_10 = { class: "w-full relative my-[15px]" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 0,
  class: "three-body1"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "my-[15px] relative w-full flex justify-end" }

import { useAuthStore } from '@/store/auth';
import { ref, computed, onMounted } from "vue"
import { useRouter } from 'vue-router';
import axios from 'axios'


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const email = ref("")
const password = ref("")
const successMessage = ref("")
const errorMessage = ref("")
const isLoading = ref(false)
const router = useRouter()
const authStore = useAuthStore();
const user = computed(() => authStore.user);

function goToRegisterPage() {
    router.push("/register")
}
function goToResetPage() {
    router.push("/reset-password")
}

const login = async() => {
    try {
        isLoading.value = true
        const response = await axios.post('/api/login/', {
            password: password.value,
            email: email.value
        })
        authStore.setToken(response.data.token);
        authStore.setUser(response.data.user);
        router.push("/dashboard")
    } catch {
        errorMessage.value = "Identifiants incorrects."
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    if (user.value) {
        router.push('/dashboard')
    }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (errorMessage.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(errorMessage.value), 1))
        : _createCommentVNode("", true),
      (successMessage.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(successMessage.value), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"md:w-6/12 relative z-10 w-full h-full md:flex hidden\" data-v-6d94d9fe><img src=\"" + _imports_0 + "\" alt=\"\" class=\"w-full h-full object-cover\" data-v-6d94d9fe><div class=\"bottom-[10px] p-[20px] absolute flex w-full justify-between items-center\" data-v-6d94d9fe><div class=\"flex items-center\" data-v-6d94d9fe><div class=\"w-[30px] h-[30px] min-w-[30px] rounded-[50%] mr-[5px] flex items-center justify-center relative overflow-hidden\" data-v-6d94d9fe><img src=\"" + _imports_1 + "\" alt=\"\" class=\"w-full h-full absolute object-cover\" data-v-6d94d9fe></div><div class=\"flex flex-col\" data-v-6d94d9fe><h1 class=\"text-xs font-bold text-white\" data-v-6d94d9fe>AGOSSA Marius</h1><p class=\"text-[10px] text-gray-100 font-semibold\" data-v-6d94d9fe>Designer UX/UI</p></div></div><div class=\"flex items-center relative right-[30px]\" data-v-6d94d9fe><a href=\"https://www.facebook.com/share/19yaSHFbGx/?mibextid=wwXIfr\" target=\"_blank\" class=\"ml-[10px]\" data-v-6d94d9fe><i class=\"fa-brands fa-facebook text-white text-sm\" data-v-6d94d9fe></i></a><a href=\"https://www.linkedin.com/company/vaybe/\" target=\"_blank\" class=\"ml-[10px]\" data-v-6d94d9fe><i class=\"fa-brands fa-linkedin text-white text-sm\" data-v-6d94d9fe></i></a><a href=\"https://www.instagram.com/vaybe.tech/profilecard/?igsh=emd4NGlrcnc5azFn\" target=\"_blank\" class=\"ml-[10px]\" data-v-6d94d9fe><i class=\"fa-brands fa-instagram text-white text-sm\" data-v-6d94d9fe></i></a></div></div></div>", 1)),
        _createElementVNode("div", _hoisted_6, [
          _cache[10] || (_cache[10] = _createElementVNode("h1", { class: "font-bold text-center text-gray-800" }, " Salut 🤗, Ravi de vous revoir ", -1)),
          _createElementVNode("p", _hoisted_7, [
            _cache[5] || (_cache[5] = _createTextVNode(" Vous n'avez pas encore de compte ? ")),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (goToRegisterPage())),
              class: "text-[#014AA9] hover:underline"
            }, " Inscrivez vous ")
          ]),
          _createElementVNode("form", {
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (login()), ["prevent"])),
            class: "my-[10px] w-full relative"
          }, [
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((email).value = $event)),
                id: "email",
                class: _normalizeClass([{'not-empty border-green-600': email.value.trim() !== ''}, "w-full p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm"])
              }, null, 2), [
                [_vModelText, email.value]
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("label", {
                for: "email",
                class: "text-sm font-bold text-gray-500 absolute top-[10px] left-[10px] label"
              }, "Entrez votre Email", -1))
            ]),
            _createElementVNode("div", _hoisted_9, [
              _withDirectives(_createElementVNode("input", {
                type: "password",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((password).value = $event)),
                id: "password",
                class: _normalizeClass([{'not-empty border-green-600': password.value.trim() !== ''}, "w-full p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm"])
              }, null, 2), [
                [_vModelText, password.value]
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("label", {
                for: "password",
                class: "text-sm font-bold text-gray-500 absolute top-[10px] left-[10px] label"
              }, "Entrez votre Mot de passe", -1))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                type: "submit",
                disabled: isLoading.value,
                class: "w-full p-[10px] bg-[#014AA9] hover:bg-blue-600 flex justify-center items-center h-[45px] duration-200 text-center font-bold rounded-[5px] text-sm text-white"
              }, [
                (isLoading.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[8] || (_cache[8] = [
                      _createElementVNode("div", { class: "three-body__dot" }, null, -1),
                      _createElementVNode("div", { class: "three-body__dot" }, null, -1),
                      _createElementVNode("div", { class: "three-body__dot" }, null, -1)
                    ])))
                  : (_openBlock(), _createElementBlock("span", _hoisted_13, "Se connecter"))
              ], 8, _hoisted_11)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (goToResetPage())),
                class: "text-gray-800 font-bold text-sm hover:underline"
              }, "Mot de passe oublié ?")
            ]),
            _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"py-[15px] relative w-full flex justify-start items-center border-t border-b border-gray-200\" data-v-6d94d9fe><input type=\"checkbox\" name=\"\" id=\"\" data-v-6d94d9fe><label class=\"text-gray-600 text-xs font-bold ml-[5px]\" data-v-6d94d9fe>Garder ma session ouverte</label></div><div class=\"my-[10px] relative w-full flex justify-end\" data-v-6d94d9fe><p class=\"text-[10px] w-full my-[15px] font-semibold text-gray-500\" data-v-6d94d9fe> En utilisant notre plateforme, vous acceptez nos <button class=\"text-[#014AA9] hover:underline\" data-v-6d94d9fe> Conditions d&#39;utilisation </button> et consentez à notre <button class=\"text-[#014AA9] hover:underline\" data-v-6d94d9fe> Politique de confidentialité </button></p></div>", 2))
          ], 32)
        ])
      ])
    ])
  ], 64))
}
}

})