import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '../assets/img/Vaybe/principal5.jpg'
import _imports_1 from '../assets/img/Logo-vaybe.png'


const _hoisted_1 = { class: "fixed top-[20px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]"
}
const _hoisted_3 = {
  key: 1,
  class: "text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]"
}
const _hoisted_4 = { class: "w-full degrade1 h-screen overflow-hidden z-0 relative flex justify-center md:p-[20px] p-[10px] items-center" }
const _hoisted_5 = { class: "container degrade2 max-w-[924px] h-full md:max-h-[550px] relative rounded-[10px] shadow-4xl flex flex-col md:flex-row justify-center items-center" }
const _hoisted_6 = {
  key: 0,
  class: "md:w-6/12 w-full h-full bg-gray-100 rounded-l-[10px] overflow-y-auto md:rounded-l-none rounded-r-[10px] md:p-[50px] p-[25px] flex flex-col justify-center items-center"
}
const _hoisted_7 = { class: "w-full relative my-[5px]" }
const _hoisted_8 = { class: "w-full relative mt-[15px]" }
const _hoisted_9 = {
  type: "submit",
  class: "bg-[#014AA9] p-[10px] h-[45px] rounded-[5px] text-sm font-bold text-white text-center flex items-center justify-center w-full"
}
const _hoisted_10 = {
  key: 0,
  class: "three-body1"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 1,
  class: "md:w-6/12 w-full h-full bg-gray-100 rounded-l-[10px] overflow-y-auto md:rounded-l-none rounded-r-[10px] md:p-[50px] p-[25px] flex flex-col justify-center items-center"
}
const _hoisted_13 = { class: "w-full relative my-[10px]" }
const _hoisted_14 = { class: "w-full relative my-[10px]" }
const _hoisted_15 = { class: "w-full relative mt-[15px]" }
const _hoisted_16 = {
  type: "submit",
  class: "bg-[#014AA9] p-[10px] h-[45px] rounded-[5px] text-sm font-bold text-white text-center flex items-center justify-center w-full"
}
const _hoisted_17 = {
  key: 0,
  class: "three-body1"
}
const _hoisted_18 = { key: 1 }

import { ref, computed, onMounted } from "vue"
import { useRouter } from 'vue-router';
import axios from 'axios'
import { useAuthStore } from '@/store/auth';


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPassword',
  setup(__props) {

const authStore = useAuthStore();
const user = computed(() => authStore.user);
const email = ref("")
const step = ref(1)
const code = ref(null)
const password = ref("")
const successMessage = ref("")
const errorMessage = ref("")
const isLoading = ref(false)
const router = useRouter()

function goToRegisterPage() {
    router.push("/register")
}

const sendCode = async() => {
    try {
        isLoading.value = true
        const response = await axios.post('/api/reset-password/', {
            email: email.value
        })
        step.value = 2
        successMessage.value = "Code de réinitialisation envoyé avec succès !"
        hideSuccessMessageAfterDelay()
    } catch {
        errorMessage.value = "Erreur lors de l'envoie du mail."
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const changePassword = async() => {
    try {
        isLoading.value = true
        const response = await axios.post('/api/change-password/', {
            email: email.value,
            resetCode: code.value,
            newPassword: password.value
        })
        router.push("/login")
    } catch {
        errorMessage.value = "Erreur lors du changement du mot de passe."
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    if (user.value) {
        router.push('/dashboard')
    }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (errorMessage.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(errorMessage.value), 1))
        : _createCommentVNode("", true),
      (successMessage.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(successMessage.value), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"md:w-6/12 relative z-10 w-full h-full md:flex hidden\"><img src=\"" + _imports_0 + "\" alt=\"\" class=\"w-full h-full object-cover\"><div class=\"bottom-[10px] p-[20px] absolute flex w-full justify-between items-center\"><div class=\"flex items-center\"><div class=\"w-[30px] h-[30px] min-w-[30px] rounded-[50%] mr-[5px] flex items-center justify-center relative overflow-hidden\"><img src=\"" + _imports_1 + "\" alt=\"\" class=\"w-full h-full absolute object-cover\"></div><div class=\"flex flex-col\"><h1 class=\"text-xs font-bold text-white\">TOSSOU Charbelle</h1><p class=\"text-[10px] text-gray-100 font-semibold\">Comptable</p></div></div><div class=\"flex items-center relative right-[30px]\"><a href=\"https://www.facebook.com/share/19yaSHFbGx/?mibextid=wwXIfr\" target=\"_blank\" class=\"ml-[10px]\"><i class=\"fa-brands fa-facebook text-white text-sm\"></i></a><a href=\"https://www.linkedin.com/company/vaybe/\" target=\"_blank\" class=\"ml-[10px]\"><i class=\"fa-brands fa-linkedin text-white text-sm\"></i></a><a href=\"https://www.instagram.com/vaybe.tech/profilecard/?igsh=emd4NGlrcnc5azFn\" target=\"_blank\" class=\"ml-[10px]\"><i class=\"fa-brands fa-instagram text-white text-sm\"></i></a></div></div></div>", 1)),
        (step.value === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("form", {
                onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (sendCode()), ["prevent"])),
                class: "w-full md:max-w-[350px] px-[20px] md:px-0 flex flex-col relative"
              }, [
                _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "text-xl mb-[15px] text-gray-800" }, "Récupérez votre compte en 2 étapes simples", -1)),
                _cache[8] || (_cache[8] = _createElementVNode("p", { class: "my-[10px] text-sm text-gray-600 text-left font-bold" }, "1/2", -1)),
                _createElementVNode("div", _hoisted_7, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", {
                    for: "email",
                    class: "text-sm font-bold text-gray-600"
                  }, "Entrez l'email de votre compte", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    name: "email",
                    id: "email",
                    required: "",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                    class: "w-full p-[10px] rounded-[5px] border border-gray-300 text-sm outline-none",
                    placeholder: "Ex: contact@vaybe.tech"
                  }, null, 512), [
                    [_vModelText, email.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", _hoisted_9, [
                    (isLoading.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[6] || (_cache[6] = [
                          _createElementVNode("div", { class: "three-body__dot" }, null, -1),
                          _createElementVNode("div", { class: "three-body__dot" }, null, -1),
                          _createElementVNode("div", { class: "three-body__dot" }, null, -1)
                        ])))
                      : (_openBlock(), _createElementBlock("span", _hoisted_11, "Envoyer le code"))
                  ])
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true),
        (step.value === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("form", {
                onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.login()), ["prevent"])),
                class: "w-full md:max-w-[350px] px-[20px] md:px-0 flex flex-col relative"
              }, [
                _cache[12] || (_cache[12] = _createElementVNode("h1", { class: "text-xl mb-[15px] text-gray-800" }, "Récupérez votre compte en 2 étapes simples", -1)),
                _cache[13] || (_cache[13] = _createElementVNode("p", { class: "my-[10px] text-sm text-gray-600 text-left font-bold" }, "2/2", -1)),
                _createElementVNode("div", _hoisted_13, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", {
                    for: "code",
                    class: "text-sm font-bold text-gray-600"
                  }, "Entrez le code reçu", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    name: "code",
                    id: "code",
                    required: "",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((code).value = $event)),
                    class: "w-full p-[10px] rounded-[5px] border border-gray-300 text-sm outline-none",
                    placeholder: "Ex: 000000"
                  }, null, 512), [
                    [_vModelText, code.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _cache[10] || (_cache[10] = _createElementVNode("label", {
                    for: "password",
                    class: "text-sm font-bold text-gray-600"
                  }, "Nouveau mot de passe", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "password",
                    name: "password",
                    id: "password",
                    required: "",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((password).value = $event)),
                    class: "w-full p-[10px] rounded-[5px] border border-gray-300 text-sm outline-none",
                    placeholder: "*******"
                  }, null, 512), [
                    [_vModelText, password.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("button", _hoisted_16, [
                    (isLoading.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[11] || (_cache[11] = [
                          _createElementVNode("div", { class: "three-body__dot" }, null, -1),
                          _createElementVNode("div", { class: "three-body__dot" }, null, -1),
                          _createElementVNode("div", { class: "three-body__dot" }, null, -1)
                        ])))
                      : (_openBlock(), _createElementBlock("span", _hoisted_18, "Changer mon mot de passe"))
                  ])
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}
}

})