<template>
    <header class="flex h-[70px] fixed w-screen z-[5000] duration-300 shadow-4xl ease-linear border-b lg:border-none bg-[#F2F4F8] justify-center items-center top-0 left-0 right-0">
        <div class="container lg:max-w-[1024px] lg:px-[20px] px-[10px] w-full lg:h-full relative flex justify-between items-center">
            <div @click="goToHomePage()" class="flex cursor-pointer logo justify-center items-center w-auto">
                <img src="../assets/img/logo-vaybe-noirp.png" alt="Logo Vaybe en noir" class="w-[100px]" id="logon">
            </div>
            <div class="toggle text-2xl mr-[10px] lg:hidden flex cursor-pointer" @click="toggleMenu()">
                <i v-if="handleMenu" class="fas fa-times duration-300"></i>
                <i v-else class="fas fa-bars duration-300"></i>
            </div>
            <nav id="nav" class="flex flex-col lg:flex-row lg:justify-between h-[calc(100vh-70px)] lg:h-full lg:overflow-y-hidden overflow-y-auto lg:relative lg:top-[2px] items-center lg:w-full fixed p-[20px] md:px-[50px] px-[20px] lg:p-0 top-[70px] w-screen left-0 right-0 lg:bg-transparent lg:translate-x-0 duration-200 bg-gray-100" :class="{'translate-x-[100%] duration-300 ease-in': !handleMenu}">
                <ul class="flex justify-center lg:h-full w-full lg:w-auto lg:ml-[50px] lg:items-center relative text-black flex-col lg:flex-row">
                    <li @click="goToHomePage()" class="lg:mx-[10px] my-[10px] lg:my-0 text-xl lg:text-sm lg:h-[calc(100%-5px)] flex lg:items-center lg:justify-center justify-between lg:px-[10px] p-[10px] py-[15px] lg:rounded-none rounded-[5px] font-semibold text-gray-700" :class="{'lg:border-b-[5px] border-[#014AA9] bg-[#4FA3E5]/10': $route.path == '/', 'border-b-[5px] border-[#F2F4F8]': $route.path != '/'}"><button>Accueil</button> <i class="fa-solid fa-chevron-right text-gray-800 fony-bold text-sm flex lg:hidden"></i></li>
                    <li @click="goToAboutPage()" class="lg:mx-[10px] my-[10px] lg:my-0 text-xl lg:text-sm lg:h-[calc(100%-5px)] flex lg:items-center lg:justify-center justify-between lg:px-[10px] p-[10px] py-[15px] lg:rounded-none rounded-[5px] font-semibold text-gray-700" :class="{'lg:border-b-[5px] border-[#014AA9] bg-[#4FA3E5]/10': $route.path == '/about', 'border-b-[5px] border-[#F2F4F8]': $route.path != '/about'}"><button>A propos</button> <i class="fa-solid fa-chevron-right text-gray-800 fony-bold text-sm flex lg:hidden"></i></li>
                    <li @click="goToSolutionsPage()" class="lg:mx-[10px] my-[10px] lg:my-0 text-xl lg:text-sm lg:h-[calc(100%-5px)] flex lg:items-center lg:justify-center justify-between lg:px-[10px] p-[10px] py-[15px] lg:rounded-none rounded-[5px] font-semibold text-gray-700" :class="{'lg:border-b-[5px] border-[#014AA9] bg-[#4FA3E5]/10': $route.path == '/solutions', 'border-b-[5px] border-[#F2F4F8]': $route.path != '/solutions'}"><button>Solutions</button> <i class="fa-solid fa-chevron-right text-gray-800 fony-bold text-sm flex lg:hidden"></i></li>
                    <li @click="goToServicesPage()" class="lg:mx-[10px] my-[10px] lg:my-0 text-xl lg:text-sm lg:h-[calc(100%-5px)] flex lg:items-center lg:justify-center justify-between lg:px-[10px] p-[10px] py-[15px] lg:rounded-none rounded-[5px] font-semibold text-gray-700" :class="{'lg:border-b-[5px] border-[#014AA9] bg-[#4FA3E5]/10': $route.path == '/services', 'border-b-[5px] border-[#F2F4F8]': $route.path != '/services'}"><button>Services</button> <i class="fa-solid fa-chevron-right text-gray-800 fony-bold text-sm flex lg:hidden"></i></li>
                    <li @click="goToBlogPage()" class="lg:mx-[10px] my-[10px] lg:my-0 text-xl lg:text-sm lg:h-[calc(100%-5px)] hidden lg:items-center lg:justify-center justify-between lg:px-[10px] p-[10px] font-semibold text-gray-700 relative" :class="{'border-b-[5px] border-[#014AA9] bg-[#4FA3E5]/10': $route.path == '/blog', 'border-b-[5px] border-[#F2F4F8]': $route.path != '/blog'}">
                        <button class="relative">
                            Blog 
                            <i class="fa-solid fa-chevron-down hidden text-xs mx-[5px]"
                            :class="{
                                'rotate-180 duration-300': handleBlog
                            }"></i>
                        </button>
                        <!--
                        <div class="lg:fixed lg:mb-[10px] hidden relative lg:translate-x-[-50%] lg:left-[50%] lg:w-[400px] lg:mt-[20px] mt-[5px] w-full z-[100000000] lg:flex lg:bg-white lg:rounded-[20px] lg:shadow-4xl pb-[25px] pt-[10px] flex-wrap" :class="{'hidden duration-300': !handleBlog}">
                            <div class="w-auto p-[8px] rounded-[20px] h-auto text-sm flex items-center text-white font-bold bg-[#4CAF50] m-[5px]">
                                <i class="fas fa-chart-line mx-[5px]"></i>
                                <span>Tendances technologiques</span>
                            </div>
                            <div class="w-auto p-[8px] rounded-[20px] h-auto text-sm flex items-center text-white font-bold bg-[#ff7707] m-[5px]">
                                <i class="fas fa-lightbulb mx-[5px]"></i>
                                <span>Conseils et astuces</span>
                            </div>
                            <div class="w-auto p-[8px] rounded-[20px] h-auto text-sm flex items-center text-white font-bold bg-[#2196F3] m-[5px]">
                                <i class="fas fa-shield-alt mx-[5px]"></i> 
                                <span>Sécurité informatique</span>
                            </div>
                            <div class="w-auto p-[8px] rounded-[20px] h-auto text-sm flex items-center text-white font-bold bg-[#E91E63] m-[5px]">
                                <i class="fas fa-laptop-code mx-[5px]"></i>
                                <span>Développement web et mobile</span>
                            </div>
                            <div class="w-auto p-[8px] rounded-[20px] h-auto text-sm flex items-center text-white font-bold bg-[#9C27B0] m-[5px]">
                                <i class="fas fa-building mx-[5px]"></i> 
                                <span>Actualités de l'entreprise</span>
                            </div>
                        </div>
                        -->
                    </li>
                </ul>
                <div class="flex flex-col w-full mt-[60px] relative lg:mt-0 lg:h-full lg:w-auto lg:flex-row justify-center lg:items-center text-black">
                    <button @click="toggleLang()" class="my-[5px] hidden lg:my-0 mx-[5px] border rounded-[5px] p-[8px] relative text-sm font-semibold text-gray-700" :class="{'border-none border-t-0': handleLang}">
                        Fr 
                        <i class="fa-solid fa-chevron-down text-xs mx-[5px]"
                        :class="{
                            'rotate-180 duration-300': handleLang
                        }"></i>
                        <div class="lg:absolute lg:bottom-0 lg:w-[100%] bg-white left-0 mt-[3px] text-left " :class="{'hidden duration-300': !handleLang}">
                            <a href="#" class="p-[8px] w-full flex justify-center items-center">
                                <i class="fas fa-globe"></i>
                                <span class="mx-[5px] bg-blue-50 ">
                                    En
                                </span>
                            </a>
                        </div>
                    </button>
                    <button @click="goToContactPage()" class="mx-[5px] my-[10px] lg:text-sm lg:h-[calc(65px)] lg:flex lg:items-center lg:justify-center lg:px-[10px] p-[10px] py-[15px] lg:w-auto rounded-[5px] lg:border-b-[5px] lg:rounded-none text-center lg:p-0 text-sm font-semibold text-gray-700" :class="{'lg:border-b-[5px] border-[#014AA9] bg-[#4FA3E5]/10': $route.path == '/contact', 'border-[0.5px] lg:border-none border-gray-400': $route.path != '/contact'}">
                        <i class="fas fa-envelope text-gray-400 text-sm lg:hidden mx-[5px]"></i>
                        Contact
                    </button>
                    <button @click="goToLoginPage()" class="my-[10px] lg:my-0 text-center lg:p-[8px] py-[15px] rounded-[5px] bg-[#014AA9] text-white text-sm font-bold mx-[5px]">Connexion</button>
                    <button @click="goToRegisterPage()" class="my-[10px] lg:my-0 hidden text-center p-[8px] rounded-[5px] border border-black bg-white text-black text-sm duration-300 ease-linear ins font-bold mx-[5px]">Inscription</button>
                    <a target="_blank" href="https://www.linkedin.com/company/vaybe/" class="my-[10px] lg:my-0 lg:w-[30px] lg:h-[30px] p-[15px] lg:p-0 lg:rounded-[50%] rounded-[5px] lg:left-0 lg:translate-x-0 relative left-[50%] translate-x-[-50%] flex justify-center items-center text-gray-700 bg-gray-200">
                        <i class="fa-brands fa-linkedin"></i>
                    </a>
                </div>
            </nav>
            
        </div>
    </header>
</template>

<script setup>

import { ref } from "vue"
import { useRouter } from 'vue-router';

const router = useRouter()

function goToLoginPage() {
    router.push("/login")
}
function goToRegisterPage() {
    router.push("/register")
}
function goToContactPage() {
    router.push("/contact")
}
function goToAboutPage() {
    router.push("/about")
}
function goToHomePage() {
    router.push("/")
}
function goToBlogPage() {
    router.push("/blog")
}
function goToSolutionsPage() {
    router.push("/solutions")
}
function goToServicesPage() {
    router.push("/services")
}

const handleMenu = ref(false)

const handleBlog = ref(false)

const handleLang = ref(false)

function toggleBlog() {
    handleBlog.value = !handleBlog.value
}

function toggleLang() {
    handleLang.value = !handleLang.value
}

function toggleMenu() {
    handleMenu.value = !handleMenu.value;
}

</script>

<style>
</style>