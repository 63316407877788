<template>
    <HeaderComponent/>
    <section class="pt-[120px] w-screen relative flex justify-center items-center">
        <div class="container lg:max-w-[1024px] w-full relative flex px-[20px] justify-center items-center">
            <div class="w-full relative flex md:flex-row flex-col-reverse items-center justify-center">
                <div class="md:w-[50%] w-full relative flex flex-col items-start justify-center h-[350px]">
                    <h1 class="text-4xl font-bold md:text-left text-center text-gray-800 mb-[20px]">Oups ! Page introuvable</h1>
                    <p class="text-xl font-semibold text-center md:text-left text-gray-500">La page que vous cherchez n’existe pas ou a été déplacée.</p>
                    <p class="text-sm font-semibold text-center md:text-left text-gray-600 mt-[10px]">Utilisez les liens ci-dessous pour continuer à explorer notre site :</p>
                    <div class="mt-[25px] flex md:justify-start w-full items-center justify-center">
                        <button @click="goToHomePage()" class="p-[10px] rounded-[5px] font-bold text-sm text-gray-800 shadow-4xl border ml-[5px] mr-[10px]">Retour à l'accueil</button>
                        <a target="_blank" href="https://wa.me/22941787700" class="p-[10px] rounded-[5px] font-bold text-sm bg-[#014AA9] text-white ml-[10px]">Contactez-nous</a>
                    </div>
                </div>
                <div class="md:w-[50%] w-full relative flex items-center justify-center overflow-hidden h-[350px]">
                    <img src="../assets/img/6339707.jpg" alt="" class="w-full h-full absolute object-contain">
                </div>
            </div>
        </div>
    </section>
    <FooterComponent/>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { useRouter } from "vue-router";

const router = useRouter()

function goToHomePage() {
    router.push("/")
}

</script>

<style lang="css" scoped>
</style>