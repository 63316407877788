<template>
    <div class="fixed top-[40px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
        <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
            {{ errorMessage }}
        </div>
        <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
            {{ successMessage }}
        </div>
    </div>
    <section class="w-full h-screen flex items-center relative justify-center">
        <div class="container lg:max-w-[1024px] flex justify-center items-center lg:p-[20px] rounded-[40px] w-screen h-full lg:max-h-[90%] relative bg-white">
            <div class="w-full h-full relative flex lg:flex-row flex-col justify-center items-center">
                <div class="lg:w-[30%] w-full lg:h-full h-[161px] bg-gray-200 lg:relative sticky top-0 z-50 p-[20px] lg:p-[25px] lg:py-[50px] flex flex-col">
                    <div class="w-full relative mb-[25px] mt-[20px] lg:mt-0 flex items-center justify-start">
                        <button @click="goBack()" class="w-[30px] h-[30px] rounded-[50%] relative flex items-center justify-center bg-white mr-[15px] text-sm font-bold text-gray-800">
                            <i class="fa-solid fa-arrow-left"></i>
                        </button>
                        <h1 class="text-sm w-[calc(100%-67.5px)] text-center lg:text-left font-bold text-gray-600">Demander Un Devis</h1>
                    </div>
                    <div class="w-full flex lg:flex-col flex-row justify-between parent lg:justify-start relative overflow-y-auto">
                        <div class="flex lg:flex-row flex-col items-center lg:w-full w-[25%] lg:justify-start justify-center">
                            <div class="w-[30px] h-[30px] rounded-[50%] relative bg-gray-300 flex items-center justify-center lg:mr-[10px] text-sm text-gray-500" :class="{'bg-gray-300 border border-gray-400': step == 1, 'bg-green-600 border text-white': step >= 2,}">
                                <i v-if="step >= 2" class="fa-solid fa-check"></i>
                                <span v-else>01</span>
                            </div>
                            <h1 class="text-xs text-center font-semibold text-gray-400" :class="{'text-gray-800 font-bold': step >= 2}"><span class="md:flex hidden">Informations de base</span><span class="md:hidden flex">Client</span></h1>
                        </div>
                        <div class="lg:w-[2px] lg:h-[50px] w-[50px] h-[2px] mt-[14px] lg:my-[10px] mx-[10px] lg:mx-0 bg-gray-300 lg:ml-[14px]" :class="{'bg-green-600': step >= 2}"></div>
                        <div class="flex lg:flex-row flex-col items-center lg:w-full w-[25%] lg:justify-start justify-center">
                            <div class="w-[30px] h-[30px] rounded-[50%] relative bg-gray-300 flex items-center justify-center lg:mr-[10px] text-sm text-gray-500" :class="{'bg-gray-300 border border-gray-400': step == 2, 'bg-green-600 border text-white': step >= 3,}">
                                <i v-if="step >= 3" class="fa-solid fa-check"></i>
                                <span v-else>02</span>
                            </div>
                            <h1 class="text-xs text-center font-semibold text-gray-400" :class="{'text-gray-800 font-bold': step >= 3}"><span class="md:flex hidden">Type de projet</span><span class="md:hidden flex">Projet</span></h1>
                        </div>
                        <div class="lg:w-[2px] lg:h-[50px] w-[50px] h-[2px] mt-[14px] lg:my-[10px] mx-[10px] lg:mx-0 bg-gray-300 lg:ml-[14px]" :class="{'bg-green-600': step >= 3}"></div>
                        <div class="flex lg:flex-row flex-col items-center lg:w-full w-[25%] lg:justify-start justify-center">
                            <div class="w-[30px] h-[30px] rounded-[50%] relative bg-gray-300 flex items-center justify-center lg:mr-[10px] text-sm text-gray-500" :class="{'bg-gray-300 border border-gray-400': step == 3, 'bg-green-600 border text-white': step >= 4,}">
                                <i v-if="step >= 4" class="fa-solid fa-check"></i>
                                <span v-else>03</span>
                            </div>
                            <h1 class="text-xs text-center font-semibold text-gray-400" :class="{'text-gray-800 font-bold': step >= 4}"><span class="md:flex hidden">Détails spécifiques</span><span class="md:hidden flex">Exigence</span></h1>
                        </div>
                        <div class="lg:w-[2px] lg:h-[50px] w-[50px] h-[2px] mt-[14px] lg:my-[10px] mx-[10px] lg:mx-0 bg-gray-300 lg:ml-[14px]" :class="{'bg-green-600': step >= 4}"></div>
                        <div class="flex lg:flex-row flex-col items-center lg:w-full w-[25%] lg:justify-start justify-center">
                            <div class="w-[30px] h-[30px] rounded-[50%] relative bg-gray-300 flex items-center justify-center lg:mr-[10px] text-sm text-gray-500" :class="{'bg-gray-300 border border-gray-400': step == 4, 'bg-green-600 border text-white': step >= 5,}">
                                <i v-if="step >= 5" class="fa-solid fa-check"></i>
                                <span v-else>04</span>
                            </div>
                            <h1 class="text-xs text-center font-semibold text-gray-400" :class="{'text-gray-800 font-bold': step >= 5}"><span class="md:flex hidden">Autre informations</span><span class="md:hidden flex">Autre</span></h1>
                        </div>
                    </div>
                </div>
                <div class="lg:w-[70%] w-full lg:h-full h-[calc(100%-161px)] overflow-y-auto parent bg-gray-100 relative p-[25px] lg:px-[50px] py-[25px] flex flex-col">
                    <form class="w-full flex flex-col relative h-auto parent">
                        <div id="step1" v-if="step == 1" class="w-full relative flex flex-col">
                            <h1 class="my-[20px] text-center flex text-gray-800 font-bold text-xl justify-center">
                                Entrez vos informations personnelles !
                            </h1>
                            <div class="w-full relative md:flex justify-between items-center">
                                <div class="md:w-[calc(50%-10px)] w-full my-[10px] relative">
                                    <input type="text" v-model="nom" id="nom" aria-label="nom" :class="{'not-empty border-green-600': nom.trim() !== ''}" class="w-full p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm" autocomplete="no">
                                    <label for="nom" class="text-sm font-bold text-gray-500 absolute top-[10px] left-[10px] label">Nom <span class="text-red-600">*</span></label>
                                    <p v-if="!nomError && prenomsError" class="h-[20px] my-[5px]"></p>
                                    <p v-if="nomError" class="flex items-center text-xs h-[20px] font-semibold text-red-800 my-[5px]">
                                        <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>
                                        <span>{{ nomError }}</span>
                                    </p>
                                </div>
                                <div class="md:w-[calc(50%-10px)] w-full my-[10px] relative">
                                    <input type="text" v-model="prenoms" id="prenoms" aria-label="prenoms" :class="{'not-empty border-green-600': prenoms.trim() !== ''}" class="w-full p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm" autocomplete="no">
                                    <label for="prenoms" class="text-sm font-bold text-gray-500 absolute top-[10px] left-[10px] label">Prénoms <span class="text-red-600">*</span></label>
                                    <p v-if="nomError && !prenomsError" class="h-[20px] my-[5px]"></p>
                                    <p v-if="prenomsError" class="flex items-center text-xs h-[20px] font-semibold text-red-800 my-[5px]">
                                        <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>
                                        <span>{{ prenomsError }}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="w-full my-[10px] relative flex flex-col">
                                <input type="text" v-model="email" id="email" aria-label="email" :class="{'not-empty border-green-600': email.trim() !== ''}" class="w-full p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm" autocomplete="no">
                                <label for="email" class="text-sm font-bold text-gray-500 absolute top-[10px] left-[10px] label">Email <span class="text-red-600">*</span></label>
                                <p v-if="emailError" class="flex items-center text-xs h-[20px] font-semibold text-red-800 my-[5px]">
                                    <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>
                                    <span>{{ emailError }}</span>
                                </p>
                            </div>
                            <div class="w-full my-[10px] relative flex flex-col">
                                <select v-model="indicatif" class="w-[75px] p-[10px] absolute left-0 h-full top-0 bg-transparent focus:border-green-600 rounded-[5px] border-r border-gray-300 outline-none input-text text-sm">
                                    <option v-for="indicatif in indicatifs" :key="indicatif" :value="indicatif">{{ indicatif }}</option>
                                </select>
                                <input type="text" v-model="phone" id="phone" aria-label="phone" :class="{'not-empty border-green-600': phone.trim() !== ''}" class="w-full p-[10px] pl-[85px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm" autocomplete="no">
                                <label for="phone" class="text-sm font-bold text-gray-500 absolute top-[10px] left-[85px] label">Numéro de téléphone <span class="text-red-600">*</span></label>
                                <p v-if="phoneError" class="flex items-center text-xs h-[20px] font-semibold text-red-800 my-[5px]">
                                    <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>
                                    <span>{{ phoneError }}</span>
                                </p>
                            </div>
                            <div class="w-full my-[10px] relative flex">
                                <input type="text" v-model="entreprise" id="entreprise" aria-label="entreprise" :class="{'not-empty border-green-600': entreprise.trim() !== ''}" class="w-full p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm" autocomplete="no">
                                <label for="entreprise" class="text-sm font-bold text-gray-500 absolute top-[10px] left-[10px] label">Nom de l’entreprise <span class="text-xs">( facultatif )</span></label>
                            </div>
                        </div>
                        <div id="step2" v-if="step == 2" class="w-full relative flex flex-col">
                            <h1 class="my-[20px] text-center flex text-gray-800 font-bold text-xl justify-center">
                                Type de projet
                            </h1>
                            <div class="w-full bg-gray-200 flex items-center justify-center relative rounded-[5px]">
                                <div @click="toggleText()" class="w-[50%] p-[10px] rounded-[5px] text-center text-sm font-semibold cursor-pointer" :class="{'bg-white text-gray-800': textPrinter === true, 'bg-gray-200 text-gray-600': textPrinter === false}">Ecrire du texte</div>
                                <div @click="toggleAudio()" class="w-[50%] p-[10px] rounded-[5px] text-center text-sm font-semibold cursor-pointer" :class="{'bg-white text-gray-800': audioRecorde === true, 'bg-gray-200 text-gray-600': audioRecorde === false}">Faire un audio</div>
                            </div>
                            <div v-if="textPrinter" class="w-full my-[10px] relative flex flex-col">
                                <label for="projet" class="text-sm font-bold text-gray-600 mb-[10px]">Projet <span class="text-red-600">*</span></label>
                                <textarea type="text" v-model="projet" id="projet" aria-label="projet" :class="{'not-empty border-green-600': projet.trim() !== ''}" class="w-full h-[300px] resize-none p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none input-text text-sm" autocomplete="no" placeholder="Parlez nous de votre projet !"></textarea>
                                <p v-if="projetError" class="flex items-center text-xs h-[20px] font-semibold text-red-800 my-[5px]">
                                    <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>
                                    <span>{{ projetError }}</span>
                                </p>
                            </div>
                            <div v-if="textPrinter" class="w-full my-[10px] relative flex flex-col">
                                <label for="type" class="text-sm font-bold text-gray-600 mb-[10px]">Quel type de projet souhaitez-vous réaliser ?</label>
                                <select v-model="type" @change="clearError" name="type" id="type" class="w-full h-[44px] p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none text-sm text-gray-800">
                                    <option value="" disabled>Choisissez un type</option>
                                    <option class="text-sm text-gray-800 bg-transparent" value="Site web">Site web</option>
                                    <option class="text-sm text-gray-800 bg-transparent" value="Application mobile">Application mobile</option>
                                    <option class="text-sm text-gray-800 bg-transparent" value="Identité visuelle">Identité visuelle</option>
                                    <option class="text-sm text-gray-800 bg-transparent" value="Autre">Autre</option>
                                </select>
                                <p v-if="typeError" class="flex items-center text-xs h-[20px] font-semibold text-red-800 my-[5px]">
                                    <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>
                                    <span>{{ typeError }}</span>
                                </p>
                                <!--
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="type" value="Site web" v-model="type" @change="clearError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Site web</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="type" value="Application mobile" v-model="type" @change="clearError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Application mobile</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="type" value="Identité visuelle" v-model="type" @change="clearError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Identité visuelle</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="type" value="Autre" v-model="type" @change="clearError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Autre</label>
                                </div> -->
                                <div class="flex items-center my-[5px]" v-if="type === 'Autre'">
                                    <input type="text" name="type" v-model="customType" class="w-full p-[10px] rounded-[5px] text-sm outline-none" placeholder="Veuillez préciser le type de projet" @input="clearError">
                                </div>
                            </div>
                            <div v-if="textPrinter" class="w-full my-[10px] relative flex flex-col">
                                <label for="stade" class="text-sm font-bold text-gray-600 mb-[10px]">À quel stade en êtes-vous dans votre projet ?</label>
                                <select v-model="stade" @change="clearStadeError" name="stade" id="stade" class="w-full h-[44px] p-[10px] bg-transparent focus:border-green-600 rounded-[5px] border border-gray-300 outline-none text-sm text-gray-800">
                                    <option value="" disabled>Choisissez le stade de votre projet</option>
                                    <option class="text-sm text-gray-800 bg-transparent" value="J’ai une idée, mais besoin de conseils">J’ai une idée, mais besoin de conseils</option>
                                    <option class="text-sm text-gray-800 bg-transparent" value="J’ai déjà un cahier des charges">J’ai déjà un cahier des charges</option>
                                    <option class="text-sm text-gray-800 bg-transparent" value="Mon projet est déjà en cours et je cherche un partenaire">Mon projet est déjà en cours et je cherche un partenaire</option>
                                </select>
                                <p v-if="stadeError" class="flex items-center text-xs h-[20px] font-semibold text-red-800 my-[5px]">
                                    <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>
                                    <span>{{ stadeError }}</span>
                                </p>
                                <!-- <div class="flex items-center my-[5px]">
                                    <input type="radio" name="stade" value="J’ai une idée, mais besoin de conseils" v-model="stade" @change="clearStadeError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">J’ai une idée, mais besoin de conseils</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="stade" value="J’ai déjà un cahier des charges" v-model="stade" @change="clearStadeError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">J’ai déjà un cahier des charges</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="stade" value="Mon projet est déjà en cours et je cherche un partenaire" v-model="stade" @change="clearStadeError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Mon projet est déjà en cours et je cherche un partenaire</label>
                                </div> -->
                            </div>
                            <div v-if="audioRecorde" class="w-full my-[20px] p-[20px] bg-gray-800 rounded-[10px] flex flex-col">
                                <h1 class="text-3xl text-white font-bold mb-[20px]">Nouvelle <br> Enregistrement</h1>
                                <p class="text-sm font-semibold text-gray-200 mb-[40px]">
                                    Enregistrez et partagez facilement avec nous votre message vocal !
                                </p>
                                <!-- Graphe sonore -->
                                <canvas ref="audioCanvas" class="w-full h-40 rounded-[10px] bg-[#374151]"></canvas>
                                <div v-if="audioBlob" class="my-[10px] w-full relative flex flex-col">
                                  <h2 class="text-sm text-white font-semibold mb-[10px]">Prévisualisation :</h2>
                                  <audio :src="audioUrl" controls class="w-full rounded-none outline-none"></audio>
                                </div>

                                <!-- Boutons de contrôle -->
                                <div class="flex items-center justify-between relative w-full my-[10px] rounded-[10px] bg-gray-700 p-[10px]">
                                  <div class="w-[110px] flex items-center justify-between">
                                    <div
                                      v-if="!isRecording"
                                      @click="startRecording"
                                      class="w-[30px] h-[30px] rounded-full flex items-center justify-center relative bg-gray-500 text-white"
                                      :disabled="isRecording"
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M8 17.175V6.825q0-.425.3-.713t.7-.287q.125 0 .263.037t.262.113l8.15 5.175q.225.15.338.375t.112.475t-.112.475t-.338.375l-8.15 5.175q-.125.075-.262.113T9 18.175q-.4 0-.7-.288t-.3-.712"/></svg>
                                    </div>
                                    <div
                                      v-if="isRecording && !isPaused"
                                      @click="pauseRecording"
                                      class="w-[30px] h-[30px] rounded-full flex items-center justify-center relative bg-red-600/40 text-red-600"
                                      :disabled="!isRecording || isPaused"
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M16 19q-.825 0-1.412-.587T14 17V7q0-.825.588-1.412T16 5t1.413.588T18 7v10q0 .825-.587 1.413T16 19m-8 0q-.825 0-1.412-.587T6 17V7q0-.825.588-1.412T8 5t1.413.588T10 7v10q0 .825-.587 1.413T8 19"/></svg>
                                    </div>
                                    <div
                                      v-if="isRecording && isPaused"
                                      @click="resumeRecording"
                                      class="w-[30px] h-[30px] rounded-full flex items-center justify-center relative bg-green-600/40 text-green-600"
                                      :disabled="isRecording || !isPaused"
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M8 17.175V6.825q0-.425.3-.713t.7-.287q.125 0 .263.037t.262.113l8.15 5.175q.225.15.338.375t.112.475t-.112.475t-.338.375l-8.15 5.175q-.125.075-.262.113T9 18.175q-.4 0-.7-.288t-.3-.712"/></svg>
                                    </div>
                                    <div
                                      @click="deleteRecording()"
                                      class="w-[30px] h-[30px] rounded-full flex items-center justify-center relative bg-gray-500 text-white"
                                      :disabled="!audioBlob"
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M6 16V8q0-.825.588-1.412T8 6h8q.825 0 1.413.588T18 8v8q0 .825-.587 1.413T16 18H8q-.825 0-1.412-.587T6 16"/></svg>
                                    </div>
                                    <div
                                      @click="deleteRecording()"
                                      class="w-[30px] h-[30px] rounded-full flex items-center justify-center relative bg-red-600 text-gray-200"
                                      :disabled="!audioBlob"
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"/></svg>
                                    </div>
                                  </div>
                                  <div class="w-[30px] flex items-center relative justify-between">
                                    <div
                                      @click="saveRecording"
                                      class="w-[30px] h-[30px] rounded-full flex items-center justify-center relative text-gray-800 bg-green-600"
                                      :disabled="!audioBlob"
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M3.291 3.309a.75.75 0 0 0-.976.996l3.093 6.945H13a.75.75 0 0 1 0 1.5H5.408l-3.093 6.945a.75.75 0 0 0 .976.996l19-8a.75.75 0 0 0 0-1.382z" clip-rule="evenodd"/></svg>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div id="step3" v-if="step == 3" class="w-full relative flex flex-col">
                            <h1 class="my-[20px] text-center flex text-gray-800 font-bold text-xl justify-center">
                                Détails spécifiques
                            </h1>
                            <div class="w-full my-[10px] relative flex flex-col">
                                <h1 class="text-sm font-bold text-gray-600 mb-[10px]">Quelles fonctionnalités/services souhaitez-vous inclure ?</h1>
                                <p v-if="fonctionsError" class="flex items-center text-xs h-[20px] font-semibold text-red-800 my-[5px]">
                                    <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>
                                    <span>{{ fonctionsError }}</span>
                                </p>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" value="Page d'accueil" v-model="fonctions">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Page d'accueil</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" value="Espace client/compte utilisateur" v-model="fonctions">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Espace client/compte utilisateur</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" value="Système de paiement en ligne" v-model="fonctions">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Système de paiement en ligne</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" value="Gestion de stock" v-model="fonctions">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Gestion de stock</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" value="Formulaire de contact" v-model="fonctions">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Formulaire de contact</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" value="Affiches publicitaire/Flyers/..." v-model="fonctions">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Affiches publicitaire/Flyers/...</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" value="Conseil et/ou Formation" v-model="fonctions">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Conseil et/ou Formation</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" value="Autre" v-model="fonctions">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Autre</label>
                                </div>
                                <div class="flex items-center my-[5px]" v-if="fonctions.includes('Autre')">
                                    <textarea type="text" v-model="autresFonctions" class="w-full p-[10px] h-[70px] resize-none rounded-[5px] text-sm outline-none" placeholder="Veuillez préciser les fonctionnalités et/ou services souhaitées"></textarea>
                                </div>
                            </div>
                            <div class="w-full my-[10px] relative flex flex-col">
                                <h1 class="text-sm font-bold text-gray-600 mb-[10px]">Quel est votre budget estimé ?</h1>
                                <p v-if="budgetError" class="flex items-center text-xs h-[20px] font-semibold text-red-800 my-[5px]">
                                    <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>
                                    <span>{{ budgetError }}</span>
                                </p>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="budget" value="Moins de 500 000 FCFA" v-model="budget" @change="clearBudgetError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Moins de 500 000 FCFA</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="budget" value="500 000 - 1 000 000 FCFA" v-model="budget" @change="clearBudgetError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">500 000 - 1 000 000 FCFA</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="budget" value="Plus de 1 000 000 FCFA" v-model="budget" @change="clearBudgetError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Plus de 1 000 000 FCFA</label>
                                </div>
                            </div>
                        </div>
                        <div id="step4" v-if="step == 4" class="w-full relative flex flex-col">
                            <h1 class="my-[20px] text-center flex text-gray-800 font-bold text-xl justify-center">
                                Délais et finalisation
                            </h1>
                            <div class="w-full my-[10px] relative flex flex-col">
                                <h1 class="text-sm font-bold text-gray-600 mb-[10px]">Quand souhaitez-vous que le projet soit terminé ?</h1>
                                <p v-if="endlineError" class="flex items-center text-xs h-[20px] font-semibold text-red-800 my-[5px]">
                                    <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>
                                    <span>{{ endlineError }}</span>
                                </p>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="endline" value="Moins d’un mois" v-model="endline" @change="clearEndlineError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Moins d’un mois</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="endline" value="1-3 mois" v-model="endline" @change="clearEndlineError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">1-3 mois</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="radio" name="endline" value="Plus de 3 mois" v-model="endline" @change="clearEndlineError">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Plus de 3 mois</label>
                                </div>
                            </div>
                            <div class="w-full my-[10px] relative flex flex-col">
                                <h1 class="text-sm font-bold text-gray-600 mb-[10px]">Comment nous avez-vous connu ?</h1>
                                <p v-if="commentError" class="text-xs text-red-800 font-semibold my-[5px]">
                                    <i class="fa-solid fa-triangle-exclamation mr-[5px]"></i>{{ commentError }}
                                </p>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" name="comment" value="Réseaux sociaux" v-model="commentSources">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Réseaux sociaux</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" name="comment" value="Recommandation" v-model="commentSources">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Recommandation</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" name="comment" value="Publicité" v-model="commentSources">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Publicité</label>
                                </div>
                                <div class="flex items-center my-[5px]">
                                    <input type="checkbox" name="comment" value="Autre" v-model="commentSources">
                                    <label for="" class="ml-[10px] text-sm text-gray-600">Autre</label>
                                </div>
                                <div class="flex items-center my-[5px]" v-if="commentSources.includes('Autre')">
                                    <textarea v-model="autreSource" class="w-full p-[10px] h-[40px] resize-none rounded-[5px] text-sm outline-none" placeholder="Veuillez préciser la source"></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div v-if="step < 5" class="w-full my-[20px] relative flex justify-between">
                        <button v-if="step > 1" @click="goToPreviewStep()" class="p-[10px] w-[calc(50%-10px)] md:w-auto text-center text-sm font-bold text-gray-800 bg-gray-300 px-[20px] rounded-[5px]">Précédent</button>
                        <div v-else></div>
                        <button v-if="step === 4" :disabled="!button_allowed" @click="submitDevis()" class="p-[10px] w-[calc(50%-10px)] md:w-auto text-center text-sm font-bold text-white bg-[#014AA9] px-[20px] rounded-[5px]" :class="{'bg-[#014AA9]/60 cursor-default': button_allowed == false}">Soumettre</button>
                        <button v-else :disabled="!button_allowed" @click="goToNextStep()" class="p-[10px] w-[calc(50%-10px)] md:w-auto text-center text-sm font-bold text-white bg-[#014AA9] px-[20px] rounded-[5px]" :class="{'bg-[#014AA9]/60 cursor-default': button_allowed == false}">{{ buttonValue }}</button>
                    </div>
                    <div v-if="isLoading" class="w-full h-full bg-gray-100 lg:px-[50px] p-[20px] absolute left-0 top-0 overflow-y-auto parent flex flex-col justify-center items-center">
                        <div class="three-body">
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                        </div>
                    </div>
                    <div v-if="devisSuccess" class="w-full h-full z-50 bg-gray-100 lg:px-[50px] p-[20px] absolute left-0 top-0 overflow-y-auto parent flex flex-col justify-center items-center">
                        <img src="../assets/img/success.png" alt="Soumission de devis réussi" class="w-[60%] max-w-[300px]">
                        <h1 class="text-xl font-bold text-center px-[10px] text-gray-800">
                            Formulaire soumis avec succès !
                        </h1>
                        <p class="text-sm font-semibold text-center my-[20px] text-gray-600">
                            Nous avons bien reçu vos informations. Nous vous enverrons par mail ou par WhatsApp votre devis dans les plus brefs délais.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script setup>
import DevisComponent from '@/components/DevisComponent.vue';
import { ref, computed, onUnmounted } from 'vue'
import { useRouter } from 'vue-router';
import axios from "axios"

const router = useRouter()

const step = ref(1)
const nom = ref('')
const textPrinter = ref(true)
const audioRecorde = ref(false)
const projet = ref('')
const indicatifs = ref([
  "+1", "+7", "+20", "+27", "+30", "+31", "+32", "+33", "+34", "+36", "+39", 
  "+40", "+41", "+43", "+44", "+45", "+46", "+47", "+48", "+49", "+51", "+52", 
  "+53", "+54", "+55", "+56", "+57", "+58", "+60", "+61", "+62", "+63", "+64", 
  "+65", "+66", "+81", "+82", "+84", "+86", "+90", "+91", "+92", "+93", "+94", 
  "+95", "+98", "+211", "+212", "+213", "+216", "+218", "+220", "+221", "+222", 
  "+223", "+224", "+225", "+226", "+227", "+228", "+229", "+230", "+231", "+232", 
  "+233", "+234", "+235", "+236", "+237", "+238", "+239", "+240", "+241", "+242", 
  "+243", "+244", "+245", "+246", "+247", "+248", "+249", "+250", "+251", "+252", 
  "+253", "+254", "+255", "+256", "+257", "+258", "+260", "+261", "+262", "+263", 
  "+264", "+265", "+266", "+267", "+268", "+269", "+290", "+291", "+297", "+298", 
  "+299"
]);
const indicatif = ref(indicatifs.value[0])
const prenoms = ref('')
const email = ref('')
const phone = ref('')
const entreprise = ref('')
const type = ref('')
const buttonValue = ref('Suivant')
const nomError = ref('')
const prenomsError = ref('')
const button_allowed = computed(() => {
    if (step.value === 1) {
        return (
            nom.value.trim() !== '' &&
            prenoms.value.trim() !== '' &&
            email.value.trim() !== '' &&
            phone.value.trim() !== ''
        );
    } else if (step.value === 2) {
        return validateType() && validateStade() || audioBlob.value;
    } else if (step.value === 3) {
        return validateFonctions() && validateBudget();
    } else if (step.value === 4) {
        return validateEndline() && validateCommentSources();
    }
    return false;
});
const emailError = ref('')
const projetError = ref('')
const phoneError = ref('')
const typeError = ref('')
const customType = ref('')
const stade = ref('')
const stadeError = ref('')
const budget = ref('')
const budgetError = ref('')
const fonctions = ref([]);
const autresFonctions = ref('');
const fonctionsError = ref('');
const endline = ref('')
const endlineError = ref('')
const commentSources = ref([]);
const autreSource = ref('');
const commentError = ref('');

const toggleText = () => {
    textPrinter.value = true
    audioRecorde.value = false
}
const toggleAudio = () => {
    textPrinter.value = false
    audioRecorde.value = true
}

const validateStade = () => {
	if (stade.value === '') {
		stadeError.value = 'Veuillez sélectionner une option.'
		return false
	}
	stadeError.value = ''
	return true
}

const clearStadeError = () => {
	stadeError.value = ''
}

const validateEndline = () => {
	if (endline.value === '') {
		endlineError.value = 'Veuillez sélectionner une option.'
		return false
	}
	endlineError.value = ''
	return true
}

const clearEndlineError = () => {
	endlineError.value = ''
}

const validateBudget = () => {
	if (budget.value === '') {
		budgetError.value = 'Veuillez sélectionner un budget.'
		return false
	}
	budgetError.value = ''
	return true
}

const clearBudgetError = () => {
	budgetError.value = ''
}

const validateFonctions = () => {
    if (fonctions.value.length === 0) {
        fonctionsError.value = 'Veuillez sélectionner au moins une fonctionnalité.';
        return false;
    }
    if (fonctions.value.includes('Autre') && autresFonctions.value.trim() === '') {
        fonctionsError.value = 'Veuillez préciser les fonctionnalités supplémentaires.';
        return false;
    }

    fonctionsError.value = '';
    return true;
};

const validateCommentSources = () => {
    if (commentSources.value.length === 0) {
        commentError.value = 'Veuillez sélectionner au moins une option.';
        return false;
    }
    if (commentSources.value.includes('Autre') && autreSource.value.trim() === '') {
        commentError.value = 'Veuillez préciser la source.';
        return false;
    }
    commentError.value = '';
    return true;
};

const validateType = () => {
	if (type.value === '') {
		typeError.value = 'Veuillez sélectionner une option.'
		return false
	}
	if (type.value === 'Autre' && customType.value.trim() === '') {
		typeError.value = 'Veuillez préciser le type de projet.'
		return false
	}
	typeError.value = ''
	return true
}

const clearError = () => {
	typeError.value = ''
}

const goToNextStep = () => {
    if (step.value === 1) {
        buttonValue.value = 'Suivant';

        nomError.value = '';
        prenomsError.value = '';
        emailError.value = '';
        phoneError.value = '';

        let hasError = false;

        if (nom.value.trim() === '') {
            nomError.value = 'Veuillez entrer votre nom';
            hasError = true;
        }
        if (prenoms.value.trim() === '') {
            prenomsError.value = 'Veuillez entrer vos prénoms';
            hasError = true;
        }
        if (email.value.trim() === '') {
            emailError.value = 'Veuillez entrer votre email';
            hasError = true;
        }
        if (phone.value.trim() === '') {
            phoneError.value = 'Veuillez entrer votre numéro de téléphone';
            hasError = true;
        }

        if (!hasError) {
            button_allowed.value = true;
            step.value += 1;
        }
    } else if (step.value === 2) {
		if (validateType() && validateStade() || audioBlob.value) {
			step.value += 1;
		}
    } else if (step.value === 3) {
        if (validateFonctions() && validateBudget()) {
            step.value += 1;
        }
    } else if (step.value === 4) {
        if (validateEndline() && validateCommentSources()) {
            step.value += 1;
        }
    }
};

const goToPreviewStep = () => {
    if (step.value > 1) {
        step.value -= 1;
    }
};

function goBack() {
    router.go(-1)
}

// Références pour l'enregistrement et l'onde sonore
const mediaRecorder = ref(null);
const chunks = ref([]);
const audioBlob = ref(null);
const audioUrl = ref("");
const isRecording = ref(false);
const isPaused = ref(false);
const audioCanvas = ref(null);
let audioContext = null;
let analyser = null;
let dataArray = null;
let canvasContext = null;
let animationFrameId = null;

// Démarrer l'enregistrement
async function startRecording() {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    alert("Votre navigateur ne supporte pas l'enregistrement audio.");
    return;
  }

  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    console.log("Microphone autorisé.");

    // Initialisation du MediaRecorder
    mediaRecorder.value = new MediaRecorder(stream);

    mediaRecorder.value.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.value.push(event.data);
      }
    };

    mediaRecorder.value.onstop = () => {
      audioBlob.value = new Blob(chunks.value, { type: "audio/webm" });
      audioUrl.value = URL.createObjectURL(audioBlob.value);
      chunks.value = [];
    };

    // Initialisation de l'oscillogramme
    audioContext = new AudioContext();
    analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;

    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    dataArray = new Uint8Array(analyser.frequencyBinCount);
    canvasContext = audioCanvas.value.getContext("2d");

    drawWaveform();

    mediaRecorder.value.start();
    isRecording.value = true;
    isPaused.value = false;
  } catch (err) {
    console.error("Erreur lors de l'accès au microphone :", err);
  }
}

// Dessiner l'onde sonore
function drawWaveform() {
  if (!analyser || !canvasContext) return;

  // Effacer le canvas
  canvasContext.clearRect(0, 0, audioCanvas.value.width, audioCanvas.value.height);

  // Obtenir les données audio
  analyser.getByteFrequencyData(dataArray);

  const canvasWidth = audioCanvas.value.width;
  const canvasHeight = audioCanvas.value.height;
  const barWidth = (canvasWidth / dataArray.length) * 10; // Ajuster la largeur des barres
  const centerY = canvasHeight / 2; // Axe central
  let x = 0;

  // Dessiner chaque barre
  for (let i = 0; i < dataArray.length; i++) {
    const value = dataArray[i] / 355; // Normaliser la hauteur des barres
    const barHeight = value * (canvasHeight / 2); // Hauteur centrée

    canvasContext.fillStyle = "#FFFFFF"; // Couleur blanche des barres
    // Dessiner la barre centrée
    canvasContext.fillRect(x, centerY - barHeight, barWidth, barHeight * 2);

    x += barWidth + 6; // Ajouter un espace entre les barres
  }

  // Animation continue
  animationFrameId = requestAnimationFrame(drawWaveform);
}


// Mettre en pause l'enregistrement
function pauseRecording() {
  if (mediaRecorder.value && mediaRecorder.value.state === "recording") {
    mediaRecorder.value.pause();
    isPaused.value = true;
    cancelAnimationFrame(animationFrameId);
  }
}

// Reprendre l'enregistrement
function resumeRecording() {
  if (mediaRecorder.value && mediaRecorder.value.state === "paused") {
    mediaRecorder.value.resume();
    isPaused.value = false;
    drawWaveform();
  }
}

// Enregistrer l'enregistrement
function saveRecording() {
  audioBlob.value = null;
  audioUrl.value = "";
  cancelAnimationFrame(animationFrameId);

  if (canvasContext) {
    canvasContext.clearRect(0, 0, audioCanvas.value.width, audioCanvas.value.height);
  }

  if (mediaRecorder.value && mediaRecorder.value.state !== "inactive") {
    mediaRecorder.value.stop();
  }

  isRecording.value = false;
  isPaused.value = false;
}

// supprimer l'enregistrement
function deleteRecording() {
  audioBlob.value = null;
  audioUrl.value = "";
  cancelAnimationFrame(animationFrameId);
  if (canvasContext) {
    canvasContext.clearRect(0, 0, audioCanvas.value.width, audioCanvas.value.height);
  }
  isRecording.value = false;
  isPaused.value = false;
}

// Nettoyage
onUnmounted(() => {
  cancelAnimationFrame(animationFrameId);
  if (audioContext) {
    audioContext.close();
  }
});

const isLoading = ref(false)
const devisSuccess = ref(false)
const errorMessage = ref("")
const successMessage = ref("")
const submitDevis = async () => {
  try {
    isLoading.value = true;
    
    const formData = new FormData();
    formData.append('nom', nom.value);
    formData.append('prenoms', prenoms.value);
    formData.append('email', email.value);
    formData.append('phone', phone.value);
    formData.append('indicatif', indicatif.value);
    formData.append('nomEntreprise', entreprise.value);
    formData.append('projet', projet.value);
    formData.append('typeProjet', type.value);
    formData.append('stadeProjet', stade.value);
    formData.append('enregistrement', audioBlob.value, 'audio.webm'); // Ajout du fichier audio
    formData.append('fonctionnalites', fonctions.value + autresFonctions.value);
    formData.append('budget', budget.value);
    formData.append('delais', endline.value);
    formData.append('com', commentSources.value + autreSource.value);

    await axios.post('/api/devis', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    successMessage.value = "Informations envoyées avec succès !"
    hideSuccessMessageAfterDelay()
    devisSuccess.value = true;
    step.value = 5
  } catch (error) {
    errorMessage.value = "Erreur lors de la soumission des informations."
    hideErrorMessageAfterDelay()
  } finally {
    isLoading.value = false;
  }
};

if(devisSuccess.value == true) {
    setTimeout(() => {
        router.push("/")
    }, 20000);
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};
</script>
  
<style lang="css" scoped>
canvas {
  display: block;
  background-color: #374151;
  border-radius: 10px;
}

</style>