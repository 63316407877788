import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '../store/auth';
import Home from '@/views/Home.vue'
import Login from "@/views/Login.vue"
import Register from "@/views/Register.vue"
import Contact from "@/views/Contact.vue"
import About from "@/views/About.vue"
import Blog from "@/views/Blog.vue"
import Solutions from "@/views/Solution.vue"
import Services from "@/views/Services.vue"
import DashboardView from '@/views/DashboardView.vue'
import DevisView from '@/views/DevisView.vue'
import Erreur404Vue from '@/views/Erreur404.vue'
import PartenaireViewVue from '@/views/PartenaireView.vue'
import ResetPasswordVue from '@/views/ResetPassword.vue'
import VerifyViewVue from '@/views/VerifyView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      description: "Boostez votre entreprise avec nos solutions digitales sur mesure : applications web, mobiles et identité visuelle.",
      keywords: "développement web, application mobile, agence digitale, UX/UI, transformation numérique",
      title: "Vaybe - Innovation et Technologie",
      scrollToTop: true,
      requiresAuth: false
    }
  },{
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false, title: "Connexion - Vaybe" },
  },{
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { requiresAuth: false, title: "Inscription - Vaybe" },
  },
  {
    path: '/verify',
    name: 'Verify',
    component: VerifyViewVue,
    meta: { requiresAuth: false, title: "Vérification de compte - Vaybe" },
  },{
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      description: "Discutons de vos projets web et mobiles. Contactez Vaybe pour des solutions digitales performantes.",
      keywords: "contact agence web, solutions numériques, projet digital, application sur mesure",
      title: "Contactez-nous - Vaybe",
      scrollToTop: true, 
      requiresAuth: false
    }
  },{
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      description: "Vaybe est une agence tech spécialisée en développement web et mobile, axée sur l'efficacité et l'expérience utilisateur.",
      keywords: "agence tech, développement web, solutions mobiles, UX/UI, expertise digitale",
      title: "À Propos - Vaybe, Experts en Digital",
      scrollToTop: true,
      requiresAuth: false
    }
  },{
    path: '/blog',
    name: 'Blog',
    component: Blog,
    meta: { 
      title: "Blog - Tendances & Innovations Digitales",
      description: "Découvrez les dernières tendances en développement web et mobile, UX/UI et transformation numérique.",
      keywords: "tendances digitales, développement web, mobile, UX/UI, innovation",
      scrollToTop: true, 
      requiresAuth: false 
    }
  },{
    path: '/solutions',
    name: 'Solutions',
    component: Solutions,
    meta: {
      description: "Vaybe s'engage dans une quête de digitalisation de l'Afrique en mettant en place des plateformes innovantes.",
      keywords: "innovation en Afrique, startup innovante, startup engageante, Afrique nouveau",
      title: "Nos Solutions - Web, Mobile & Innovation",
      scrollToTop: true,
      requiresAuth: false
    }
  },{
    path: '/services',
    name: 'services',
    component: Services,
    meta: {
      description: "Développement d’applications web et mobiles, design UX/UI et identité visuelle.",
      keywords: "services numériques internationaux, UX/UI design Europe Afrique, création d’identité visuelle entreprise, solutions digitales sur mesure",
      title: "Nos Services – Développement Web, Mobile & Identité Visuelle",
      scrollToTop: true,
      requiresAuth: false
    }
  },{
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { 
      title: "Tableau de Bord - Vaybe",
      scrollToTop: true,
      requiresAuth: true 
    }
  },{
    path: '/devis',
    name: 'devis',
    component: DevisView,
    meta: { 
      title: "Obtenir un Devis - Vaybe",
      description: "Besoin d’un projet web ou mobile ? Obtenez un devis personnalisé en quelques clics.",
      keywords: "devis web, devis application mobile, estimation projet digital",
      scrollToTop: true,
      requiresAuth: false 
    }
  },{
    path: '/partenaires',
    name: 'partenaires',
    component: PartenaireViewVue,
    meta: {
      title: "Nos Partenaires - Collaboration Digitale",
      description: "Découvrez nos collaborations stratégiques pour des solutions digitales innovantes et performantes.",
      keywords: "partenaires tech, collaborations digitales, solutions innovantes",
      scrollToTop: true,
      requiresAuth: false
    }
  },{
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPasswordVue,
    meta: { 
      title: "Réinitialiser le Mot de Passe - Vaybe",
      scrollToTop: true,
      requiresAuth: false 
    }
  },{
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: Erreur404Vue,
    meta: { 
      title: "Page Introuvable - Vaybe",
      scrollToTop: true,
      requiresAuth: false 
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to: any, from: any, savedPosition: any) {
    if (to.meta.scrollToTop) {
      return { top: 0 };
    }
  },
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.token) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

router.beforeEach((to: any, from: any, next) => {
  document.title = to.meta.title || "Vaybe";
  
  const descriptionMeta = document.querySelector('meta[name="description"]');
  if (descriptionMeta && to.meta.description) {
    descriptionMeta.setAttribute('content', to.meta.description);
  }

  const keywordsMeta = document.querySelector('meta[name="keywords"]');
  if (keywordsMeta && to.meta.keywords) {
    keywordsMeta.setAttribute('content', to.meta.keywords);
  }

  next();
});

export default router
