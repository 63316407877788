<template>
    <section class="w-full fixed top-0 left-0 bg-white h-[70px] flex lg:hidden items-center justify-center">
        <div class="container lg:max-w-[1024px] w-full px-[20px] relative flex items-center justify-between">
            <h1 class="text-sm font-bold text-gray-800">
                Tableau de Bord
            </h1>
            <div class="toggle text-2xl mr-[10px] lg:hidden flex cursor-pointer" @click="toggleMenu()">
                <i v-if="handleMenu" class="fas fa-times duration-300">a</i>
                <i v-else class="fas fa-bars duration-300">b</i>
            </div>
        </div>
    </section>
    <header :class="{'translate-x-[-100%]': !handleMenu, 'translate-x-0': handleMenu}" class="lg:w-[30%] w-[70%] max-w-[300px] lg:max-w-[30%] lg:translate-x-0 duration-300 ease-out h-screen fixed top-0 left-0 bg-white flex flex-col justify-start lg:p-[25px] p-[15px] py-[50px]">
        <div class="w-full relative lg:px-[25px] px-[15px] mb-[50px]">
            <img src="../assets/img/logo-vaybe-noirp.png" alt="" class="w-[100px]">
        </div>
        <div class="w-full relative flex items-center text-base py-[10px] lg:px-[25px] px-[15px] my-[5px] rounded-[5px] text-[#2C2C2C]/90 hover:bg-[#F7B801] duration-300 cursor-pointer" :class="{'bg-[#F7B801] font-semibold text-[#2C2C2C]/90': $route.path == '/dashboard'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M16 20q-.425 0-.712-.288T15 19v-5q0-.425.288-.712T16 13h5q.425 0 .713.288T22 14v5q0 .425-.288.713T21 20zm-4-9q-.425 0-.712-.288T11 10V5q0-.425.288-.712T12 4h9q.425 0 .713.288T22 5v5q0 .425-.288.713T21 11zm-9 9q-.425 0-.712-.288T2 19v-5q0-.425.288-.712T3 13h9q.425 0 .713.288T13 14v5q0 .425-.288.713T12 20zm0-9q-.425 0-.712-.288T2 10V5q0-.425.288-.712T3 4h5q.425 0 .713.288T9 5v5q0 .425-.288.713T8 11z"/></svg>
            <h1 class="ml-[10px]">Dashboard</h1>
        </div>
        <div class="w-full relative flex items-center text-base py-[10px] lg:px-[25px] px-[15px] rounded-[5px] my-[5px] text-[#2C2C2C]/90 hover:bg-[#F7B801] duration-300 cursor-pointer" :class="{'bg-[#F7B801] font-semibold text-[#2C2C2C]': $route.path == '/articles'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21zm3-4h5q.425 0 .713-.288T14 16t-.288-.712T13 15H8q-.425 0-.712.288T7 16t.288.713T8 17m0-4h8q.425 0 .713-.288T17 12t-.288-.712T16 11H8q-.425 0-.712.288T7 12t.288.713T8 13m0-4h8q.425 0 .713-.288T17 8t-.288-.712T16 7H8q-.425 0-.712.288T7 8t.288.713T8 9"/></svg>
            <h1 class="ml-[10px]">Articles</h1>
        </div>
        <div class="w-full relative flex items-center text-base py-[10px] lg:px-[25px] px-[15px] rounded-[5px] my-[5px] text-[#2C2C2C]/90 hover:bg-[#F7B801] duration-300 cursor-pointer" :class="{'bg-[#F7B801] font-semibold text-[#2C2C2C]': $route.path == '/messagerie'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="m5 8.75l-2.75-3.1q-.425-.5-.162-1.075Q2.35 4 3 4h17q.825 0 1.413.588Q22 5.175 22 6v12q0 .825-.587 1.413Q20.825 20 20 20H7q-.825 0-1.412-.587Q5 18.825 5 18ZM9 13h9q.425 0 .712-.288Q19 12.425 19 12t-.288-.713Q18.425 11 18 11H9q-.425 0-.712.287Q8 11.575 8 12t.288.712Q8.575 13 9 13Zm0 3h6q.425 0 .713-.288Q16 15.425 16 15t-.287-.713Q15.425 14 15 14H9q-.425 0-.712.287Q8 14.575 8 15t.288.712Q8.575 16 9 16Zm0-6h9q.425 0 .712-.288Q19 9.425 19 9t-.288-.713Q18.425 8 18 8H9q-.425 0-.712.287Q8 8.575 8 9t.288.712Q8.575 10 9 10Z"/></svg>
            <h1 class="ml-[10px]">Messagerie</h1>
        </div>
        <div class="w-full relative flex items-center text-base py-[10px] lg:px-[25px] px-[15px] rounded-[5px] my-[5px] text-[#2C2C2C]/90 hover:bg-[#F7B801] duration-300 cursor-pointer" :class="{'bg-[#F7B801] font-semibold text-[#2C2C2C]': $route.path == '/projets'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M5.4 2h13.2A3.4 3.4 0 0 1 22 5.4v13.2a3.4 3.4 0 0 1-3.4 3.4H5.4A3.4 3.4 0 0 1 2 18.6V5.4A3.4 3.4 0 0 1 5.4 2M7 5a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1m5 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1m6 1a1 1 0 1 0-2 0v10a1 1 0 1 0 2 0z" clip-rule="evenodd"/></svg>
            <h1 class="ml-[10px]">Projets</h1>
        </div>
        <div class="w-full relative flex items-center text-base py-[10px] lg:px-[25px] px-[15px] rounded-[5px] my-[5px] text-[#2C2C2C]/90 hover:bg-[#F7B801] duration-300 cursor-pointer" :class="{'bg-[#F7B801] font-semibold text-[#2C2C2C]': $route.path == '/projets'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M11.846 17.25v.635q0 .146.11.255t.256.11h.23q.162 0 .283-.121t.121-.283v-.596h1.5q.213 0 .357-.144t.143-.356v-3q0-.213-.143-.356t-.357-.144h-3.5v-2h3.5q.213 0 .357-.144t.143-.357t-.143-.356t-.357-.143h-1.5v-.635q0-.146-.11-.255q-.109-.11-.255-.11h-.231q-.161 0-.283.121q-.12.121-.12.283v.596h-1.5q-.213 0-.357.144t-.144.356v3q0 .213.144.356t.356.144h3.5v2h-3.5q-.212 0-.356.144t-.144.357t.144.356t.356.143zM6.616 21q-.691 0-1.153-.462T5 19.385V4.615q0-.69.463-1.152T6.616 3h8.115L19 7.27v12.115q0 .69-.462 1.153T17.384 21zM14.23 4v2.962q0 .343.232.575t.576.232H18z"/></svg>
            <h1 class="ml-[10px]">Devis</h1>
        </div>
        <div class="w-[calc(100%-50px)] absolute bottom-[50px] left-[25px]">
            <div class="w-full relative flex items-center text-base py-[10px] lg:px-[25px] px-[15px] rounded-[5px] my-[5px] text-[#2C2C2C]/90 hover:bg-[#F7B801] duration-300 cursor-pointer">
                <div class="flex flex-col">
                    <h1 class="text-base font-bold text-gray-800">{{ nom }}</h1>
                    <p class="text-xs text-gray-500">{{ user.poste ? user.poste : 'Non défini' }}</p>
                </div>
            </div>
            <div @click="logout()" class="w-full relative flex items-center text-base py-[10px] lg:px-[25px] px-[15px] rounded-[5px] my-[5px] text-[#2C2C2C]/90 hover:bg-[#F7B801] duration-300 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M10 8V6a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2v-2"/><path d="M15 12H3l3-3m0 6l-3-3"/></g></svg>
                <h1 class="ml-[10px]">Se déconnecter</h1>
            </div>
        </div>
    </header>
</template>

<script setup>
import { useAuthStore } from '@/store/auth';
import { ref, computed, onMounted } from "vue"

const handleMenu = ref(false)
const isLoading = ref(false)
const errorMessage = ref("")
const successMessage = ref("")
const authStore = useAuthStore();
const user = computed(() => authStore.user);

const nom = user.value.firstname + ' ' + user.value.lastname

function toggleMenu() {
    handleMenu.value = !handleMenu.value;
}

const logout = async () => {
    isLoading.value = true
    try {
        authStore.logout();
        router.push('/login');
    } catch (err) {
        errorMessage.value = "Erreur lors de la déconnexion.";
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false;
    }
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};
</script>

<style>

</style>