<template>
    <section class="w-full degrade1 h-screen overflow-hidden z-0 relative flex justify-center md:p-[20px] p-[10px] items-center">
        <div class="container degrade2 max-w-[924px] h-full md:max-h-[550px] relative rounded-[10px] shadow-4xl flex flex-col md:flex-row justify-center items-center">
            <div class="md:w-6/12 relative z-10 w-full h-full md:flex hidden">
                <img src="../assets/img/Vaybe/principal5.jpg" alt="" class="w-full h-full object-cover">
            </div>
            <div class="md:w-6/12 w-full h-full bg-gray-100 rounded-l-[10px] overflow-y-auto md:rounded-l-none rounded-r-[10px] md:p-[50px] p-[25px] flex flex-col justify-center items-center">
                <h2 class="text-2xl font-bold text-center mb-4">Vérification de l'email</h2>
                <p class="text-center text-gray-500 mb-6">Entrez le code que vous avez reçu par email</p>
                <form @submit.prevent="handleVerification">
                    <div class="grid grid-cols-6 gap-2 mb-4">
                        <input v-for="n in 6" :key="n" type="text" v-model="code[n - 1]" maxlength="1" class="text-center border border-gray-300 rounded p-2 text-xl" @input="moveToNext(n - 1)" />
                    </div>
                    <button type="submit" :disabled="isLoading" class="w-full p-[10px] bg-[#014AA9] hover:bg-blue-600 flex justify-center items-center h-[40px] duration-200 text-center font-bold rounded-[5px] text-sm text-white">
                        <span v-if="!isLoading">Vérifier</span>
                        <div v-if="isLoading" class="spinner"></div>
                    </button>
                    <p v-if="error" class="text-red-500 text-center mt-4">{{ error }}</p>
                    <div class="my-[10px] relative w-full flex justify-end mt-4">
                        <p class="text-[10px] w-full my-[15px] font-semibold text-gray-500">
                            En utilisant notre plateforme, vous acceptez nos <button class="text-blue-500 hover:underline"> Conditions d'utilisation </button> et consentez à notre <button class="text-blue-500 hover:underline"> Politique de confidentialité </button>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();
const user = computed(() => authStore.user);
const code = ref(['', '', '', '', '', '']);
const error = ref(null);
const router = useRouter();
const email = ref(router.currentRoute.value.query.email || localStorage.getItem('userEmail'));
const isLoading = ref(false)

async function handleVerification() {
    isLoading.value = true
    const verificationCode = code.value.join('');
    try {
        const response = await axios.post('/api/verify', { email: email.value, code: Number(verificationCode) });
        router.push('/login');
    } catch (err) {
        error.value = err.response.data.error || 'Code de vérification incorrect';
    } finally {
        isLoading.value = false
    }
}

function moveToNext(index) {
  if (code.value[index].length === 1 && index < 5) {
    document.querySelectorAll('input')[index + 1].focus();
  }
}

onMounted(() => {
    if (user.value) {
        router.push('/dashboard')
    }
})
</script>
