<template>
    <div class="fixed top-[20px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
        <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
            {{ errorMessage }}
        </div>
        <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
            {{ successMessage }}
        </div>
    </div>
    <section class="w-full degrade1 h-screen overflow-hidden z-0 relative flex justify-center md:p-[20px] p-[10px] items-center">
        <div class="container degrade2 max-w-[924px] h-full md:max-h-[550px] relative rounded-[10px] shadow-4xl flex flex-col md:flex-row justify-center items-center">
            <div class="md:w-6/12 relative z-10 w-full h-full md:flex hidden">
                <img src="../assets/img/Vaybe/principal5.jpg" alt="" class="w-full h-full object-cover">
                <div class="bottom-[10px] p-[20px] absolute flex w-full justify-between items-center">
                    <div class="flex items-center">
                        <div class="w-[30px] h-[30px] min-w-[30px] rounded-[50%] mr-[5px] flex items-center justify-center relative overflow-hidden">
                            <img src="../assets/img/Logo-vaybe.png" alt="" class="w-full h-full absolute object-cover">
                        </div>
                        <div class="flex flex-col">
                            <h1 class="text-xs font-bold text-white">TOSSOU Charbelle</h1>
                            <p class="text-[10px] text-gray-100 font-semibold">Comptable</p>
                        </div>
                    </div>
                    <div class="flex items-center relative right-[30px]">
                        <a href="https://www.facebook.com/share/19yaSHFbGx/?mibextid=wwXIfr" target="_blank" class="ml-[10px]"><i class="fa-brands fa-facebook text-white text-sm"></i></a>
                        <a href="https://www.linkedin.com/company/vaybe/" target="_blank" class="ml-[10px]"><i class="fa-brands fa-linkedin text-white text-sm"></i></a>
                        <a href="https://www.instagram.com/vaybe.tech/profilecard/?igsh=emd4NGlrcnc5azFn" target="_blank" class="ml-[10px]"><i class="fa-brands fa-instagram text-white text-sm"></i></a>
                    </div>
                </div>
            </div>
            <div v-if="step === 1" class="md:w-6/12 w-full h-full bg-gray-100 rounded-l-[10px] overflow-y-auto md:rounded-l-none rounded-r-[10px] md:p-[50px] p-[25px] flex flex-col justify-center items-center">
                <form @submit.prevent="sendCode()" class="w-full md:max-w-[350px] px-[20px] md:px-0 flex flex-col relative">
                    <h1 class="text-xl mb-[15px] text-gray-800">Récupérez votre compte en 2 étapes simples</h1>
                    <p class="my-[10px] text-sm text-gray-600 text-left font-bold">1/2</p>
                    <div class="w-full relative my-[5px]">
                        <label for="email" class="text-sm font-bold text-gray-600">Entrez l'email de votre compte</label>
                        <input type="text" name="email" id="email" required v-model="email" class="w-full p-[10px] rounded-[5px] border border-gray-300 text-sm outline-none" placeholder="Ex: contact@vaybe.tech">
                    </div>
                    <div class="w-full relative mt-[15px]">
                        <button type="submit" class="bg-[#014AA9] p-[10px] h-[45px] rounded-[5px] text-sm font-bold text-white text-center flex items-center justify-center w-full">
                            <div v-if="isLoading" class="three-body1">
                                <div class="three-body__dot"></div>
                                <div class="three-body__dot"></div>
                                <div class="three-body__dot"></div>
                            </div>
                            <span v-else>Envoyer le code</span>
                        </button>
                    </div>
                </form>
            </div>
            <div v-if="step === 2" class="md:w-6/12 w-full h-full bg-gray-100 rounded-l-[10px] overflow-y-auto md:rounded-l-none rounded-r-[10px] md:p-[50px] p-[25px] flex flex-col justify-center items-center">
                <form @submit.prevent="login()" class="w-full md:max-w-[350px] px-[20px] md:px-0 flex flex-col relative">
                    <h1 class="text-xl mb-[15px] text-gray-800">Récupérez votre compte en 2 étapes simples</h1>
                    <p class="my-[10px] text-sm text-gray-600 text-left font-bold">2/2</p>
                    <div class="w-full relative my-[10px]">
                        <label for="code" class="text-sm font-bold text-gray-600">Entrez le code reçu</label>
                        <input type="text" name="code" id="code" required v-model="code" class="w-full p-[10px] rounded-[5px] border border-gray-300 text-sm outline-none" placeholder="Ex: 000000">
                    </div>
                    <div class="w-full relative my-[10px]">
                        <label for="password" class="text-sm font-bold text-gray-600">Nouveau mot de passe</label>
                        <input type="password" name="password" id="password" required v-model="password" class="w-full p-[10px] rounded-[5px] border border-gray-300 text-sm outline-none" placeholder="*******">
                    </div>
                    <div class="w-full relative mt-[15px]">
                        <button type="submit" class="bg-[#014AA9] p-[10px] h-[45px] rounded-[5px] text-sm font-bold text-white text-center flex items-center justify-center w-full">
                            <div v-if="isLoading" class="three-body1">
                                <div class="three-body__dot"></div>
                                <div class="three-body__dot"></div>
                                <div class="three-body__dot"></div>
                            </div>
                            <span v-else>Changer mon mot de passe</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
  
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import { useRouter } from 'vue-router';
import axios from 'axios'
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();
const user = computed(() => authStore.user);
const email = ref("")
const step = ref(1)
const code = ref(null)
const password = ref("")
const successMessage = ref("")
const errorMessage = ref("")
const isLoading = ref(false)
const router = useRouter()

function goToRegisterPage() {
    router.push("/register")
}

const sendCode = async() => {
    try {
        isLoading.value = true
        const response = await axios.post('/api/reset-password/', {
            email: email.value
        })
        step.value = 2
        successMessage.value = "Code de réinitialisation envoyé avec succès !"
        hideSuccessMessageAfterDelay()
    } catch {
        errorMessage.value = "Erreur lors de l'envoie du mail."
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const changePassword = async() => {
    try {
        isLoading.value = true
        const response = await axios.post('/api/change-password/', {
            email: email.value,
            resetCode: code.value,
            newPassword: password.value
        })
        router.push("/login")
    } catch {
        errorMessage.value = "Erreur lors du changement du mot de passe."
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    if (user.value) {
        router.push('/dashboard')
    }
})
</script>

<style scoped>
</style>