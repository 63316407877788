import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '../assets/img/Vaybe/principal5.jpg'
import _imports_1 from '../assets/img/Logo-vaybe.png'


const _hoisted_1 = { class: "fixed top-[20px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]"
}
const _hoisted_3 = {
  key: 1,
  class: "text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]"
}
const _hoisted_4 = { class: "w-full degrade1 h-screen overflow-hidden z-0 relative flex justify-center md:p-[20px] p-[10px] items-center" }
const _hoisted_5 = { class: "container degrade2 max-w-[924px] h-full md:max-h-[550px] relative rounded-[10px] shadow-4xl flex flex-col md:flex-row justify-center items-center" }
const _hoisted_6 = { class: "md:w-6/12 w-full lg:h-full h-full bg-gray-100 overflow-y-auto parent rounded-l-[10px] md:rounded-l-none rounded-r-[10px] md:px-[50px] p-[25px] flex flex-col justify-center items-center" }
const _hoisted_7 = { class: "text-xs text-center my-[15px] font-semibold text-gray-500" }
const _hoisted_8 = { class: "w-full relative md:flex justify-between items-center" }
const _hoisted_9 = { class: "md:w-[48%] w-full relative mt-[15px]" }
const _hoisted_10 = { class: "md:w-[48%] w-full relative mt-[15px]" }
const _hoisted_11 = { class: "w-full relative my-[15px]" }
const _hoisted_12 = { class: "w-full relative my-[15px]" }
const _hoisted_13 = { class: "w-full relative my-[15px]" }
const _hoisted_14 = { class: "w-full relative my-[15px]" }
const _hoisted_15 = {
  type: "submit",
  disabled: "",
  class: "w-full p-[10px] bg-[#014AA9] hover:bg-blue-600 flex justify-center items-center h-[45px] duration-200 text-center font-bold rounded-[5px] text-sm text-white"
}
const _hoisted_16 = {
  key: 0,
  class: "three-body1"
}
const _hoisted_17 = { key: 1 }

import { useAuthStore } from '@/store/auth';
import { onMounted, ref, computed } from "vue"
import { useRouter } from 'vue-router';
import axios from 'axios'


export default /*@__PURE__*/_defineComponent({
  __name: 'Register',
  setup(__props) {

const email = ref("")
const password = ref("")
const nom = ref("")
const prenoms = ref("")
const Cpassword = ref("")
const errorMessage = ref("")
const successMessage = ref("")
const isLoading = ref(false)
const router = useRouter()
const authStore = useAuthStore();
const user = computed(() => authStore.user);

function goToLoginPage() {
    router.push("/login")
}

const register = async() => {
    if ( password.value !== Cpassword.value ) {
        errorMessage.value = 'Les mots de passe ne correspondent'
        hideErrorMessageAfterDelay()
        return
    }
    try {
        isLoading.value = true
        const response = await axios.post('/api/register/', {
            email: email.value,
            firstname: prenoms.value,
            lastname: nom.value,
            password: password.value
        })
        router.push({ path: '/verify', query: { email: email.value } });
    } catch {
        errorMessage.value = "Erreur lors de l'enregistrement de l'utilisateur."
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    if (user.value) {
        router.push('/dashboard')
    }
})

function goToHomePage() {
    router.push("/")
}

/* onMounted(() => {
    router.push("/partenaires")
}) */

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (errorMessage.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(errorMessage.value), 1))
        : _createCommentVNode("", true),
      (successMessage.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(successMessage.value), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"md:w-6/12 relative z-10 w-full h-full md:flex hidden\" data-v-29600056><img src=\"" + _imports_0 + "\" alt=\"\" class=\"w-full h-full object-cover\" data-v-29600056><div class=\"bottom-[10px] p-[20px] absolute flex w-full justify-between items-center\" data-v-29600056><div class=\"flex items-center\" data-v-29600056><div class=\"w-[30px] h-[30px] min-w-[30px] rounded-[50%] mr-[5px] flex items-center justify-center relative overflow-hidden\" data-v-29600056><img src=\"" + _imports_1 + "\" alt=\"\" class=\"w-full h-full absolute object-cover\" data-v-29600056></div><div class=\"flex flex-col\" data-v-29600056><h1 class=\"text-xs font-bold text-white\" data-v-29600056>AGOSSA Mickaël</h1><p class=\"text-[10px] text-gray-100 font-semibold\" data-v-29600056>Développeur Full Stack &amp; IA</p></div></div><div class=\"flex items-center relative right-[30px]\" data-v-29600056><a href=\"https://www.facebook.com/share/19yaSHFbGx/?mibextid=wwXIfr\" target=\"_blank\" class=\"ml-[10px]\" data-v-29600056><i class=\"fa-brands fa-facebook text-white text-sm\" data-v-29600056></i></a><a href=\"https://www.linkedin.com/company/vaybe/\" target=\"_blank\" class=\"ml-[10px]\" data-v-29600056><i class=\"fa-brands fa-linkedin text-white text-sm\" data-v-29600056></i></a><a href=\"https://www.instagram.com/vaybe.tech/profilecard/?igsh=emd4NGlrcnc5azFn\" target=\"_blank\" class=\"ml-[10px]\" data-v-29600056><i class=\"fa-brands fa-instagram text-white text-sm\" data-v-29600056></i></a></div></div></div>", 1)),
        _createElementVNode("div", _hoisted_6, [
          _cache[10] || (_cache[10] = _createElementVNode("h1", { class: "font-bold text-center text-gray-800" }, " Salut 🤗, Bienvenue sur notre plateforme ! ", -1)),
          _createElementVNode("p", _hoisted_7, [
            _cache[7] || (_cache[7] = _createTextVNode(" Vous avez déjà un compte ? ")),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (goToLoginPage())),
              class: "text-[#014AA9] hover:underline"
            }, " Connectez vous ")
          ]),
          _createElementVNode("form", {
            onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"])),
            class: "my-[10px] w-full relative"
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  required: "",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((prenoms).value = $event)),
                  class: "p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500",
                  placeholder: "Prénoms"
                }, null, 512), [
                  [_vModelText, prenoms.value]
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  required: "",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((nom).value = $event)),
                  class: "p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500",
                  placeholder: "Nom"
                }, null, 512), [
                  [_vModelText, nom.value]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                required: "",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((email).value = $event)),
                class: "p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500",
                placeholder: "Email"
              }, null, 512), [
                [_vModelText, email.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _withDirectives(_createElementVNode("input", {
                type: "password",
                required: "",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((password).value = $event)),
                class: "p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500",
                placeholder: "Mot de passe"
              }, null, 512), [
                [_vModelText, password.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _withDirectives(_createElementVNode("input", {
                type: "password",
                required: "",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((Cpassword).value = $event)),
                class: "p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500",
                placeholder: "Confirmez le Mot de passe"
              }, null, 512), [
                [_vModelText, Cpassword.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("button", _hoisted_15, [
                (isLoading.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[8] || (_cache[8] = [
                      _createElementVNode("div", { class: "three-body__dot" }, null, -1),
                      _createElementVNode("div", { class: "three-body__dot" }, null, -1),
                      _createElementVNode("div", { class: "three-body__dot" }, null, -1)
                    ])))
                  : (_openBlock(), _createElementBlock("span", _hoisted_17, "S'inscrire"))
              ])
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "my-[10px] relative w-full flex justify-end" }, [
              _createElementVNode("p", { class: "text-[10px] w-full mt-[15px] font-semibold text-gray-500" }, [
                _createTextVNode(" En utilisant notre plateforme, vous acceptez nos "),
                _createElementVNode("button", { class: "text-[#014AA9] hover:underline" }, " Conditions d'utilisation "),
                _createTextVNode(" et consentez à notre "),
                _createElementVNode("button", { class: "text-blue-500 hover:underline" }, " Politique de confidentialité ")
              ])
            ], -1))
          ], 32)
        ])
      ])
    ])
  ], 64))
}
}

})