<template>
    <HeaderComponent/>
    <section class="w-full h-full relative flex bg-[#014AA9] items-center mt-[70px] justify-center p-[20px]">
        <div class="w-full h-full absolute left-0 flex lg:flex-row flex-col items-center justify-center">
            <div class="lg:w-[60%] w-full lg:h-full h-[60%] relative flex items-center justify-center overflow-hidden">

            </div>
            <div class="lg:w-[40%] w-full lg:h-full h-[40%] relative flex items-center justify-center overflow-hidden">
                <img src="../assets/img/office.jpg" alt="" class="w-full h-full object-cover absolute">
            </div>
        </div>
        <div class="w-full lg:h-[400px] h-auto lg:max-w-[1024px] rounded-[20px] relative py-[20px] flex items-center justify-center">
            <div class="container w-full h-full lg:max-w-[1024px] px-[20px] relative flex lg:flex-row flex-col items-center justify-center">
                <div class="lg:w-[60%] w-full h-full relative flex flex-col items-start justify-center">
                    <button class="p-[10px] text-white border border-white rounded-[5px] text-sm font-bold">
                        Solution sur mesure
                    </button>
                    <h1 class="text-white text-2xl font-bold my-[10px]">
                        Un client unique mérite un service unique ! Votre prochain projet commence ici !
                    </h1>
                    <div class="flex flex-col w-full relative items-start justify-between">
                        <div class="w-full flex flex-col items-start">
                            <div class="flex items-center">
                                <i class="fa-solid fa-add mr-[5px] text-white"></i>
                                <h1 class="text-white text-sm font-bold my-[10px]">
                                    Accompagnement personnalisé pour des résultats concrets
                                </h1>
                            </div>
                        </div>
                        <div class="w-full flex flex-col items-start">
                            <div class="flex items-center">
                                <i class="fa-solid fa-add mr-[5px] text-white"></i>
                                <h1 class="text-white text-sm font-bold my-[10px]">
                                    Design d’excellence et expérience utilisateur au cœur de chaque projet
                                </h1>
                            </div>
                        </div>
                    </div>
                    <button @click="goToDevisPage()" class="p-[10px] text-[#2C2C2C] bg-[#F7B801] mt-[20px] rounded-[5px] text-sm font-bold">
                        Demander un devis
                    </button>
                </div>
                <div class="lg:w-[40%] w-full lg:h-full h-[300px] z-10 relative flex items-center justify-center overflow-hidden">
                    
                </div>
            </div>
        </div>
    </section>
    <section class="w-full relative flex items-center justify-center py-[50px]">
        <div class="container lg:max-w-[1024px] px-[20px] w-full relative flex flex-col items-center justify-center">
            <h1 class="w-full text-center max-w-[600px] text-3xl font-bold text-gray-800 mb-[20px]">Nos services conçuent pour aider nos clients, vous aider !</h1>
            <div class="w-full relative flex items-center justify-between flex-wrap">
                <div class="md:w-[calc(50%-10px)] w-full bg-blue-100 rounded-[20px] p-[20px] md:h-[225px] h-auto my-[10px] flex md:flex-row flex-col items-center justify-center">
                    <div class="md:w-[70%] w-full h-full relative flex flex-col justify-center items-center">
                        <div class="w-full flex items-center mb-[10px] relative">
                            <div class="w-[30px] h-[30px] min-h-[30px] min-w-[30px] rounded-[50%] bg-blue-200 flex items-center justify-center text-sm font-bold text-[#2C2C2C] mr-[10px]">
                                1
                            </div>
                            <h1 class="font-bold text-base text-[#2C2C2C]">
                                Développement Web & Mobile
                            </h1>
                        </div>
                        <div class="w-full relative">
                            <p class="text-xs font-semibold text-[#2C2C2C]/70">
                                Votre vision, notre expertise. 
                                Nous croyons que chaque projet est unique. C’est pourquoi nous collaborons étroitement avec vous pour concevoir des applications innovantes, performantes, et capables de faire la différence. Rejoignez-nous pour donner vie à des produits digitaux à la hauteur de votre ambition.
                            </p>
                        </div>
                    </div>
                    <div class="md:w-[30%] w-full h-full min-h-[200px] relative flex items-center justify-center overflow-hidden">
                        <img src="../assets/img/Vaybe/web_mobile.png" alt="" class="w-full h-full absolute object-contain object-center">
                    </div>
                </div>
                <div class="md:w-[calc(50%-10px)] w-full bg-blue-100 rounded-[20px] p-[20px] md:h-[225px] h-auto my-[10px] flex md:flex-row flex-col items-center justify-center">
                    <div class="md:w-[70%] w-full h-full relative flex flex-col justify-center items-center">
                        <div class="w-full flex items-center mb-[10px] relative">
                            <div class="w-[30px] h-[30px] min-h-[30px] min-w-[30px] rounded-[50%] bg-blue-200 flex items-center justify-center text-sm font-bold text-[#2C2C2C] mr-[10px]">
                                2
                            </div>
                            <h1 class="font-bold text-base text-[#2C2C2C]">
                                Design UX/UI
                            </h1>
                        </div>
                        <div class="w-full relative">
                            <p class="text-xs font-semibold text-[#2C2C2C]/70">
                                Des designs sur-mesure pour captiver votre audience. 
                                Du premier clic à la dernière interaction, nous créons des parcours utilisateurs optimisés pour convertir. Nos experts UX/UI façonnent des interfaces intuitives, modernes et entièrement personnalisées, assurant une expérience inoubliable à chaque utilisateur.
                            </p>
                        </div>
                    </div>
                    <div class="md:w-[30%] w-full min-h-[200px] h-full relative flex items-center justify-center overflow-hidden">
                        <img src="../assets/img/Vaybe/ux_ui.png" alt="" class="w-full h-full absolute object-contain object-center">
                    </div>
                </div>
                <div class="md:w-[calc(50%-10px)] w-full bg-blue-100 rounded-[20px] p-[20px] md:h-[225px] h-auto my-[10px] flex md:flex-row flex-col items-center justify-center">
                    <div class="md:w-[70%] w-full h-full relative flex flex-col justify-center items-center">
                        <div class="w-full flex items-center mb-[10px] relative">
                            <div class="w-[30px] h-[30px] min-h-[30px] min-w-[30px] rounded-[50%] bg-blue-200 flex items-center justify-center text-sm font-bold text-[#2C2C2C] mr-[10px]">
                                3
                            </div>
                            <h1 class="font-bold text-base text-[#2C2C2C]">
                                Conseil & Accompagnement Digital
                            </h1>
                        </div>
                        <div class="w-full relative">
                            <p class="text-xs font-semibold text-[#2C2C2C]/70">
                                Une expertise qui booste votre succès. 
                                Nos experts vous aident à prendre les bonnes décisions pour accélérer votre croissance. Avec un accompagnement personnalisé, nous optimisons vos processus, renforçons votre stratégie et vous aidons à atteindre vos objectifs dans un environnement digital en constante évolution.
                            </p>
                        </div>
                    </div>
                    <div class="md:w-[30%] w-full h-full min-h-[200px] relative flex items-center justify-center overflow-hidden">
                        <img src="../assets/img/Vaybe/conseil.png" alt="" class="w-full h-full absolute object-contain object-center">
                    </div>
                </div>
                <div class="md:w-[calc(50%-10px)] w-full bg-blue-100 rounded-[20px] p-[20px] md:h-[225px] h-auto my-[10px] flex md:flex-row flex-col items-center justify-center">
                    <div class="md:w-[70%] w-full h-full relative flex flex-col justify-center items-center">
                        <div class="w-full flex items-center mb-[10px] relative">
                            <div class="w-[30px] h-[30px] min-h-[30px] min-w-[30px] rounded-[50%] bg-blue-200 flex items-center justify-center text-sm font-bold text-[#2C2C2C] mr-[10px]">
                                4
                            </div>
                            <h1 class="font-bold text-base text-[#2C2C2C]">
                                Formation & Atelier
                            </h1>
                        </div>
                        <div class="w-full relative">
                            <p class="text-xs font-semibold text-[#2C2C2C]/70">
                                Développez vos compétences pour un avenir digital. 
                                Que vous soyez débutant ou expert, nous proposons des ateliers interactifs et des formations sur mesure pour répondre aux besoins de votre équipe. Améliorez vos connaissances, gagnez en autonomie, et boostez la croissance de votre entreprise avec nos programmes de formation.
                            </p>
                        </div>
                    </div>
                    <div class="md:w-[30%] w-full h-full relative min-h-[200px] flex items-center justify-center overflow-hidden">
                        <img src="../assets/img/Vaybe/formation.png" alt="" class="w-full h-full absolute object-contain object-center">
                    </div>
                </div>
                <div class="w-[calc(100%)] bg-blue-100 rounded-[20px] p-[20px] md:h-[150px] h-auto my-[10px] flex md:flex-row flex-col items-center justify-center">
                    <div class="md:w-[70%] w-full h-full relative flex flex-col justify-center items-center">
                        <div class="w-full flex items-center mb-[10px] relative">
                            <div class="w-[80px] h-[30px] min-h-[30px] min-w-[80px] rounded-[50px] bg-blue-200 flex items-center justify-center text-sm font-bold text-[#2C2C2C] mr-[10px]">
                                Bonus
                            </div>
                            <h1 class="font-bold text-base text-[#2C2C2C]">
                                Maintenance et Support Technique
                            </h1>
                        </div>
                        <div class="w-full relative">
                            <p class="text-xs font-semibold text-[#2C2C2C]/70">
                                Assistance continue pour un succès sans faille. 
                                Du dépannage à la mise à jour, nous sommes là pour vous à chaque étape. Avec un support technique réactif et une surveillance proactive, Vaybe garantit la stabilité et la sécurité de vos applications, pour que vous puissiez vous concentrer sur votre cœur de métier.
                            </p>
                        </div>
                    </div>
                    <div class="md:w-[30%] w-full h-full min-h-[200px] md:min-h-full relative flex items-center justify-center overflow-hidden">
                        <img src="../assets/img/Vaybe/bonus.png" alt="" class="w-full h-full absolute object-contain object-center">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="w-full relative flex items-center justify-center py-[50px]">
        <div class="container lg:max-w-[1024px] px-[20px] w-full relative flex flex-col items-center justify-center">
            <div class="w-full lg:h-[250px] rounded-[20px] p-[20px] bg-[#014AA9] flex lg:flex-row flex-col items-center justify-center relative">
                <div class="lg:w-[60%] w-full mb-[20px] relative flex flex-col justify-center items-start">
                    <h1 class="text-2xl mb-[20px] font-bold text-white capitalize">Connectez vous pour mieux nous expliquer votre sujet</h1>
                    <p class="text-sm font-semibold text-gray-100">Nous avons mis à disposition un chat instantané qui nous permettra de mieux discuter pour mieux comprendre vos attente afin de vous fournir une meilleur solution !</p>
                    <p class="mt-[10px] text-xs font-bold text-gray-300">
                        Un temps de réponse inférieur à 5h
                    </p>
                </div>
                <div class="lg:w-[40%] w-full h-full flex items-center justify-center flex-col lg:px-[20px] relative">
                    <form action="" class="w-full relative flex flex-col">
                        <div class="w-full relative flex my-[5px]">
                            <label for="" class="text-sm font-bold text-gray-600 absolute top-[10px] left-[10px]">Email</label>
                            <input type="text" class="w-full p-[10px] rounded-[10px] bg-gray-100 text-sm border border-gray-300 outline-none">
                        </div>
                        <div class="w-full relative flex my-[5px]">
                            <label for="" class="text-sm font-bold text-gray-600 absolute top-[10px] left-[10px]">Mot de passe</label>
                            <input type="text" class="w-full p-[10px] rounded-[10px] bg-gray-100 text-sm border border-gray-300 outline-none">
                        </div>
                        <div class="w-full relative my-[10px]">
                            <button class="bg-[#F7B801] p-[10px] rounded-[50px] text-sm font-bold text-[#2C2C2C] text-center flex items-center justify-center w-full">Se connecter</button>
                        </div>
                        <div class="w-full relative my-[5px] flex justify-center">
                            <h1 class="text-xs font-bold text-gray-100 mr-[5px] text-center">Vous n'avez pas encore de compte ? <button @click="goToRegisterPage()" class="text-[#F7B801] text-xs font-bold hover:underline">S'inscrire</button></h1>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <FooterComponent/>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { ref } from "vue"
import { useRouter } from "vue-router";

const router = useRouter()

function goToRegisterPage() {
    router.push('/register')
}
function goToDevisPage() {
    router.push("/devis")
}
const articles = ref([
    {
        link: 'liste-des-meilleurs-montres-de-chez-dior-a-moins-de-200€',
        image: './img/montre.jpeg',
        categorie: 'Montre de luxe',
        title: 'Liste des meilleurs montres de chez Dior à moins de 200€',
        content: 'Si vous voulez vous démarquez du lot avec des montres de luxe et tout ça sans se ruiner, vous êtes au bon endroit. Ici nous vous présentons la liste des meilleurs montre Dior à moins de 200€',
        like: '99k',
        view: '102k',
        date: '10 juin 2024',
        profile: './img/profile.jpeg',
        autor: 'TOSSOU Charbelle'
    },
    {
        link: 'liste-des-meilleurs-montres-de-chez-dior-a-moins-de-200€',
        image: './img/montre.jpeg',
        categorie: 'Montre de luxe',
        title: 'Liste des meilleurs montres de chez Dior à moins de 200€',
        content: 'Si vous voulez vous démarquez du lot avec des montres de luxe et tout ça sans se ruiner, vous êtes au bon endroit. Ici nous vous présentons la liste des meilleurs montre Dior à moins de 200€',
        like: '99k',
        view: '102k',
        date: '10 juin 2024',
        profile: './img/profile.jpeg',
        autor: 'TOSSOU Charbelle'
    },
    {
        link: 'liste-des-meilleurs-montres-de-chez-dior-a-moins-de-200€',
        image: './img/montre.jpeg',
        categorie: 'Montre de luxe',
        title: 'Liste des meilleurs montres de chez Dior à moins de 200€',
        content: 'Si vous voulez vous démarquez du lot avec des montres de luxe et tout ça sans se ruiner, vous êtes au bon endroit. Ici nous vous présentons la liste des meilleurs montre Dior à moins de 200€',
        like: '99k',
        view: '102k',
        date: '10 juin 2024',
        profile: './img/profile.jpeg',
        autor: 'TOSSOU Charbelle'
    },
])

</script>

<style lang="css" scoped>
#unique::after {
    content: '';
    width: 70px;
    height: 70px;
    border-radius: 17% 55% 0% 17% / 15% 53% 68% 10% ;
    background-color: #F2F4F8;
    position: absolute;
    top: -30px;
    right: 10px;
    z-index: 1;
}
#unique::before {
    content: '';
    width: 70px;
    height: 70px;
    border-radius: 55% 17% 14% 0% / 77% 15% 19% 78% ;
    background-color: #F2F4F8;
    position: absolute;
    bottom: -30px;
    left: 10px;
    z-index: 1;
}
@media screen and (max-width: 1023px) {
    #uniqueMobile::after {
        content: '';
        width: 50px;
        height: 50px;
        border-radius: 17% 55% 0% 17% / 15% 53% 68% 10% ;
        background-color: #F2F4F8;
        position: absolute;
        top: -10px;
        right: 10px;
        z-index: 1;
    }
    #uniqueMobile::before {
        content: '';
        width: 50px;
        height: 50px;
        border-radius: 55% 17% 14% 0% / 77% 15% 19% 78% ;
        background-color: #F2F4F8;
        position: absolute;
        bottom: -10px;
        left: 10px;
        z-index: 1;
    }
}
#citation {
    background: url("../assets/img/fond_gandhi.png") center center no-repeat;
    background-size: cover;
}

#forme {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}
#forme1 {
    border-radius: 45% 75% 85% 45% / 55% 55% 85% 85%;
}

</style>