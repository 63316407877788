<template>
    <HeaderComponent/>
    <section class="py-[100px] flex justify-center items-center flex-col">
        <div class="bg-gray-100 w-screen flex justify-center items-center py-[15px]">
            <div class="container max-w-[1024px] px-[20px] flex justify-between items-center">
                <h1 class="text-blue-950 font-bold">Blog</h1>
                <span class="text-xs text-gray-700 uppercase">
                    Home / Blog / List
                </span>
            </div>
        </div>
        <div class="container max-w-[1024px] w-full mt-[20px] relative px-[10px] flex justify-center items-center">
            <div class="flex w-full flex-col md:flex-row relative justify-center md:items-start items-center">
                <div class="w-[350px] md:mr-[10px] md:p-0 p-[10px] relative">
                    <div class="w-full flex relative">
                        <input type="text" name="search" id="search" class="w-full p-[8px] outline-none rounded-[20px] text-sm font-bold pr-[35px] px-[15px] placeholder:font-bold shadow-8xl" placeholder="Rechercher...">
                        <label for="search"><i class="fa-solid fa-search text-xs font-bold absolute right-[15px] top-[10px]"></i></label>
                    </div>
                    <div class="my-[25px] bg-gray-100 md:block hidden w-full relative p-[20px]">
                        <h1 class="py-[10px] border-b text-sm font-bold text-gray-500">Tendances technologiques</h1>
                        <h1 class="py-[10px] border-b text-sm font-bold text-gray-500">Conseils et astuces</h1>
                        <h1 class="py-[10px] border-b text-sm font-bold text-gray-500">Sécurité informatique</h1>
                        <h1 class="py-[10px] border-b text-sm font-bold text-gray-500">Développement web et mobile</h1>
                        <h1 class="py-[10px] border-b text-sm font-bold text-gray-500">Actualités de l'entreprise</h1>
                    </div>
                    <div class="bg-gray-100 w-full hidden md:block relative p-[20px]">
                        <h1 class="mb-[20px] mt-[10px] text-xl font-bold text-gray-800">Top posts</h1>
                        <div class="w-full relative flex my-[10px] items-start">
                            <span class="text-4xl font-bold font-mono text-black mr-[10px]">
                                1
                            </span>
                            <div class="">
                                <h1 class="text-sm text-black font-bold mb-[5px]">Titre de notre article un titre pas trop long</h1>
                                <span class="text-gray-400 uppercase text-xs">
                                    Tendances technologiques - 06/11/2023
                                </span>
                            </div>
                        </div>
                        <div class="w-full relative flex my-[10px] items-start">
                            <span class="text-4xl font-bold font-mono text-black mr-[10px]">
                                2
                            </span>
                            <div class="">
                                <h1 class="text-sm text-black font-bold mb-[5px]">Titre de notre article un titre pas trop long</h1>
                                <span class="text-gray-400 uppercase text-xs">
                                    Conseils et astuces - 06/11/2023
                                </span>
                            </div>
                        </div>
                        <div class="w-full relative flex my-[10px] items-start">
                            <span class="text-4xl font-bold font-mono text-black mr-[10px]">
                                3
                            </span>
                            <div class="">
                                <h1 class="text-sm text-black font-bold mb-[5px]">Titre de notre article un titre pas trop long</h1>
                                <span class="text-gray-400 uppercase text-xs">
                                    Sécurité informatique - 06/11/2023
                                </span>
                            </div>
                        </div>
                        <div class="w-full relative flex my-[10px] items-start">
                            <span class="text-4xl font-bold font-mono text-black mr-[10px]">
                                4
                            </span>
                            <div class="">
                                <h1 class="text-sm text-black font-bold mb-[5px]">Titre de notre article un titre pas trop long</h1>
                                <span class="text-gray-400 uppercase text-xs">
                                    Développement web et mobile - 06/11/2023
                                </span>
                            </div>
                        </div>
                        <div class="w-full relative flex my-[10px] items-start">
                            <span class="text-4xl font-bold font-mono text-black mr-[10px]">
                                5
                            </span>
                            <div class="">
                                <h1 class="text-sm text-black font-bold mb-[5px]">Titre de notre article un titre pas trop long</h1>
                                <span class="text-gray-400 uppercase text-xs">
                                    Actualités de l'entreprise - 06/11/2023
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full md:ml-[10px] md:p-0 px-0 p-[10px] relative">
                    <div class="flex justify-center items-center mt-[-20px] flex-wrap">
                        <div class="md:w-[220px] w-[150px] relative my-[20px] m-[10px]">
                            <div class="w-full md:h-[250px] h-[190px] flex justify-center relative items-center overflow-hidden rounded-[20px]">
                                <img src="../assets/img/FB_IMG_16960563837016422.jpg" alt="" class="w-full h-full">
                                <div class="absolute left-[50%] translate-x-[-50%] md:h-[35px] h-[25px] p-[5px] md:w-[140px] w-[125px] flex justify-center items-center bg-white rounded-[30px] bottom-[20px]">
                                    <div class="md:w-[30px] w-[20px] md:h-[30px] h-[20px] rounded-[50%] overflow-hidden flex justify-center items-center">
                                        <img src="../assets/img/gandhi.png" alt="">
                                    </div>
                                    <h1 class="md:mx-[5px] ml-[5px] text-center flex flex-nowrap font-bold text-xs text-blue-800">Mickaël agossa</h1>
                                </div>
                            </div>
                            <h1 class="p-[10px] md:font-bold font-semibold md:text-[16px] text-sm">
                                Titre de notre article un titre pas trop long
                            </h1>
                            <div class="border-t p-[10px] border-gray-300 flex justify-between items-center">
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-calendar mr-[5px]"></i>
                                    6/11/2019 
                                </span>
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-eye mr-[5px]"></i>
                                    102k 
                                </span>
                            </div>
                            <button class="text-xs font-bold text-center w-full border border-black hover:text-white hover:border-blue-950 hover:bg-blue-950 duration-300 p-[5px] rounded-[20px]">Lire plus</button>
                        </div>
                        <div class="md:w-[220px] w-[150px] relative my-[20px] m-[10px]">
                            <div class="w-full md:h-[250px] h-[190px] flex justify-center relative items-center overflow-hidden rounded-[20px]">
                                <img src="../assets/img/FB_IMG_16960563837016422.jpg" alt="" class="w-full h-full">
                                <div class="absolute left-[50%] translate-x-[-50%] md:h-[35px] h-[25px] p-[5px] md:w-[140px] w-[125px] flex justify-center items-center bg-white rounded-[30px] bottom-[20px]">
                                    <div class="md:w-[30px] w-[20px] md:h-[30px] h-[20px] rounded-[50%] overflow-hidden flex justify-center items-center">
                                        <img src="../assets/img/gandhi.png" alt="">
                                    </div>
                                    <h1 class="md:mx-[5px] ml-[5px] text-center flex flex-nowrap font-bold text-xs text-blue-800">Mickaël agossa</h1>
                                </div>
                            </div>
                            <h1 class="p-[10px] md:font-bold font-semibold md:text-[16px] text-sm">
                                Titre de notre article un titre pas trop long
                            </h1>
                            <div class="border-t p-[10px] border-gray-300 flex justify-between items-center">
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-calendar mr-[5px]"></i>
                                    6/11/2019 
                                </span>
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-eye mr-[5px]"></i>
                                    102k 
                                </span>
                            </div>
                            <button class="text-xs font-bold text-center w-full border border-black hover:text-white hover:border-blue-950 hover:bg-blue-950 duration-300 p-[5px] rounded-[20px]">Lire plus</button>
                        </div>
                        <div class="md:w-[220px] w-[150px] relative my-[20px] m-[10px]">
                            <div class="w-full md:h-[250px] h-[190px] flex justify-center relative items-center overflow-hidden rounded-[20px]">
                                <img src="../assets/img/FB_IMG_16960563837016422.jpg" alt="" class="w-full h-full">
                                <div class="absolute left-[50%] translate-x-[-50%] md:h-[35px] h-[25px] p-[5px] md:w-[140px] w-[125px] flex justify-center items-center bg-white rounded-[30px] bottom-[20px]">
                                    <div class="md:w-[30px] w-[20px] md:h-[30px] h-[20px] rounded-[50%] overflow-hidden flex justify-center items-center">
                                        <img src="../assets/img/gandhi.png" alt="">
                                    </div>
                                    <h1 class="md:mx-[5px] ml-[5px] text-center flex flex-nowrap font-bold text-xs text-blue-800">Mickaël agossa</h1>
                                </div>
                            </div>
                            <h1 class="p-[10px] md:font-bold font-semibold md:text-[16px] text-sm">
                                Titre de notre article un titre pas trop long
                            </h1>
                            <div class="border-t p-[10px] border-gray-300 flex justify-between items-center">
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-calendar mr-[5px]"></i>
                                    6/11/2019 
                                </span>
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-eye mr-[5px]"></i>
                                    102k 
                                </span>
                            </div>
                            <button class="text-xs font-bold text-center w-full border border-black hover:text-white hover:border-blue-950 hover:bg-blue-950 duration-300 p-[5px] rounded-[20px]">Lire plus</button>
                        </div>
                        <div class="md:w-[220px] w-[150px] relative my-[20px] m-[10px]">
                            <div class="w-full md:h-[250px] h-[190px] flex justify-center relative items-center overflow-hidden rounded-[20px]">
                                <img src="../assets/img/FB_IMG_16960563837016422.jpg" alt="" class="w-full h-full">
                                <div class="absolute left-[50%] translate-x-[-50%] md:h-[35px] h-[25px] p-[5px] md:w-[140px] w-[125px] flex justify-center items-center bg-white rounded-[30px] bottom-[20px]">
                                    <div class="md:w-[30px] w-[20px] md:h-[30px] h-[20px] rounded-[50%] overflow-hidden flex justify-center items-center">
                                        <img src="../assets/img/gandhi.png" alt="">
                                    </div>
                                    <h1 class="md:mx-[5px] ml-[5px] text-center flex flex-nowrap font-bold text-xs text-blue-800">Mickaël agossa</h1>
                                </div>
                            </div>
                            <h1 class="p-[10px] md:font-bold font-semibold md:text-[16px] text-sm">
                                Titre de notre article un titre pas trop long
                            </h1>
                            <div class="border-t p-[10px] border-gray-300 flex justify-between items-center">
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-calendar mr-[5px]"></i>
                                    6/11/2019 
                                </span>
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-eye mr-[5px]"></i>
                                    102k 
                                </span>
                            </div>
                            <button class="text-xs font-bold text-center w-full border border-black hover:text-white hover:border-blue-950 hover:bg-blue-950 duration-300 p-[5px] rounded-[20px]">Lire plus</button>
                        </div>
                        <div class="md:w-[220px] w-[150px] relative my-[20px] m-[10px]">
                            <div class="w-full md:h-[250px] h-[190px] flex justify-center relative items-center overflow-hidden rounded-[20px]">
                                <img src="../assets/img/FB_IMG_16960563837016422.jpg" alt="" class="w-full h-full">
                                <div class="absolute left-[50%] translate-x-[-50%] md:h-[35px] h-[25px] p-[5px] md:w-[140px] w-[125px] flex justify-center items-center bg-white rounded-[30px] bottom-[20px]">
                                    <div class="md:w-[30px] w-[20px] md:h-[30px] h-[20px] rounded-[50%] overflow-hidden flex justify-center items-center">
                                        <img src="../assets/img/gandhi.png" alt="">
                                    </div>
                                    <h1 class="md:mx-[5px] ml-[5px] text-center flex flex-nowrap font-bold text-xs text-blue-800">Mickaël agossa</h1>
                                </div>
                            </div>
                            <h1 class="p-[10px] md:font-bold font-semibold md:text-[16px] text-sm">
                                Titre de notre article un titre pas trop long
                            </h1>
                            <div class="border-t p-[10px] border-gray-300 flex justify-between items-center">
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-calendar mr-[5px]"></i>
                                    6/11/2019 
                                </span>
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-eye mr-[5px]"></i>
                                    102k 
                                </span>
                            </div>
                            <button class="text-xs font-bold text-center w-full border border-black hover:text-white hover:border-blue-950 hover:bg-blue-950 duration-300 p-[5px] rounded-[20px]">Lire plus</button>
                        </div>
                        <div class="md:w-[220px] w-[150px] relative my-[20px] m-[10px]">
                            <div class="w-full md:h-[250px] h-[190px] flex justify-center relative items-center overflow-hidden rounded-[20px]">
                                <img src="../assets/img/FB_IMG_16960563837016422.jpg" alt="" class="w-full h-full">
                                <div class="absolute left-[50%] translate-x-[-50%] md:h-[35px] h-[25px] p-[5px] md:w-[140px] w-[125px] flex justify-center items-center bg-white rounded-[30px] bottom-[20px]">
                                    <div class="md:w-[30px] w-[20px] md:h-[30px] h-[20px] rounded-[50%] overflow-hidden flex justify-center items-center">
                                        <img src="../assets/img/gandhi.png" alt="">
                                    </div>
                                    <h1 class="md:mx-[5px] ml-[5px] text-center flex flex-nowrap font-bold text-xs text-blue-800">Mickaël agossa</h1>
                                </div>
                            </div>
                            <h1 class="p-[10px] md:font-bold font-semibold md:text-[16px] text-sm">
                                Titre de notre article un titre pas trop long
                            </h1>
                            <div class="border-t p-[10px] border-gray-300 flex justify-between items-center">
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-calendar mr-[5px]"></i>
                                    6/11/2019 
                                </span>
                                <span class="enfant1 text-gray-500 duration-300 flex text-xs items-center">
                                    <i class="fa-solid fa-eye mr-[5px]"></i>
                                    102k 
                                </span>
                            </div>
                            <button class="text-xs font-bold text-center w-full border border-black hover:text-white hover:border-blue-950 hover:bg-blue-950 duration-300 p-[5px] rounded-[20px]">Lire plus</button>
                        </div>
                    </div>
                    <div class="flex mt-[10px] items-center justify-center lg:justify-end w-full relative">
                        <div class="w-[35px] h-[35px] mr-[10px] border flex justify-center items-center text-sm font-bold bg-blue-900 text-white">1</div>
                        <div class="w-[35px] h-[35px] mr-[10px] border flex justify-center items-center text-sm font-bold cursor-pointer bg-white text-blue-900">2</div>
                        <div class="w-[35px] h-[35px] mr-[10px] border flex justify-center items-center text-sm font-bold cursor-pointer bg-white text-blue-900">3</div>
                        <div class="w-[35px] h-[35px] mr-[10px] border flex justify-center items-center text-sm font-bold cursor-pointer bg-white text-blue-900">4</div>
                        <div class="w-[35px] h-[35px] mr-[10px] border flex justify-center items-center text-sm font-bold cursor-pointer bg-white text-blue-900"><i class="fa-solid fa-chevron-right text-[10px]"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import HeaderComponent from "@/components/HeaderComponent.vue";
import { useRouter } from 'vue-router';

const router = useRouter()

function goToHomePage() {
    router.push("/")
}

onMounted(() => {
    goToHomePage()
})

</script>

<style lang="css" scoped>
.enfant3 {
    transform: translateX(-100px);
    opacity: 0;
}

.affichage:hover .enfant3 {
    transform: translateX(0);
    transform: translateY(-25px);
    opacity: 1;
}

.affichage:hover .enfant2 {
    transform: translateY(-25px);
    background-color: white;
}
</style>