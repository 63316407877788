<template>
    <HeaderComponent/>
    <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
        <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
            {{ errorMessage }}
        </div>
        <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
            {{ successMessage }}
        </div>
    </div>
    <section class="mt-[70px] flex w-full relative items-center justify-center h-[450px]">
        <div class="w-full h-full absolute top-0 left-0 flex items-center justify-center">
            <img src="../assets/img/partenaire3.png" alt="" class="w-full h-full absolute object-cover">
        </div>
        <div class="w-full h-full absolute top-0 left-0 flex items-center justify-center bg-gray-800/80"></div>
        <div class="container lg:max-w-[1024px] w-full px-[20px] flex items-center flex-col justify-center relative">
            <h1 class="text-3xl font-bold lg:text-center text-left text-white">Devenez partenaire de Vaybe et digitalisons l’Afrique ensemble</h1>
            <p class="text-sm font-semibold text-gray-100 lg:text-center text-left max-w-[850px] my-[20px]">
                Rejoignez Vaybe, la startup béninoise spécialisée dans les services digitaux, et profitez d’une collaboration innovante pour transformer le paysage numérique en Afrique. Que vous soyez une entreprise, une agence ou un freelance, ensemble, nous pouvons offrir des solutions digitales performantes et accessibles à tous.
            </p>
            <button @click="togglePartenariat()" class="p-[10px] w-full lg:w-auto bg-white text-[#014AA9] text-sm font-bold rounded-[5px]">
                Nous rejoindre
            </button>
        </div>
    </section>
    <section class="w-full my-[100px] flex relative justify-center items-center">
        <div class="container lg:max-w-[1024px] px-[20px] relative w-full flex justify-center items-center">
            <div class="w-full flex lg:flex-row flex-col items-center justify-center relative">
                <div class="lg:w-[50%] w-full relative">
                    <h2 class="text-xl font-bold text-gray-400 uppercase">Pourquoi devenir partenaire ?</h2>
                    <p class="text-3xl font-bold text-gray-800 mb-[20px]">
                        Un partenariat gagnant-gagnant pour un impact maximal
                    </p>
                    <p class="text-gray-600">
                        Chez Vaybe, nous croyons en la puissance des collaborations stratégiques. En devenant partenaire, vous bénéficiez de :
                    </p>
                    <ul>
                        <li class="flex text-sm font-semibold text-gray-700 items-start justify-start mb-[10px]">
                            <i class="fa-solid fa-check-square text-green-600 mr-[5px] mt-[2.5px]"></i>
                            Visibilité accrue grâce à notre réseau grandissant de clients et d’entreprises en Afrique.
                        </li>
                        <li class="flex text-sm font-semibold text-gray-700 items-start justify-start mb-[10px]">
                            <i class="fa-solid fa-check-square text-green-600 mr-[5px] mt-[2.5px]"></i>
                            Opportunités d’affaires en intégrant nos solutions digitales et nos services sur mesure.
                        </li>
                        <li class="flex text-sm font-semibold text-gray-700 items-start justify-start mb-[10px]">
                            <i class="fa-solid fa-check-square text-green-600 mr-[5px] mt-[2.5px]"></i>
                            Expertise complémentaire en développement web, mobile, intelligence artificielle et transformation digitale.
                        </li>
                        <li class="flex text-sm font-semibold text-gray-700 items-start justify-start mb-[10px]">
                            <i class="fa-solid fa-check-square text-green-600 mr-[5px] mt-[2.5px]"></i>
                            Accès à des ressources exclusives et à notre écosystème d’innovation technologique.
                        </li>
                    </ul>
                </div>
                <div class="lg:w-[50%] mt-[20px] w-full relative flex items-center justify-center lg:h-[400px] h-[300px]">
                    <div class="w-[40%] m-[10px] relative h-full">
                        <div class="w-full h-[45%] flex items-center justify-center relative overflow-hidden my-[10px] rounded-[5px] rounded-tl-[25px]">
                            <img src="../assets/img/Vaybe/Marius_1.jpg" alt="" class="w-full h-full absolute object-cover">
                        </div>
                        <div class="w-full h-[45%] flex items-center justify-center relative overflow-hidden my-[10px] rounded-[5px] rounded-br-[25px]">
                            <img src="../assets/img/Vaybe/Marius_2.jpg" alt="" class="w-full h-full absolute object-cover">
                        </div>
                    </div>
                    <div class="w-[40%] m-[10px] h-[90%] mt-[-10%] flex items-center justify-center relative overflow-hidden rounded-[5px] rounded-br-[25px]">
                        <img src="../assets/img/Vaybe/Marius3.jpg" alt="" class="w-full h-full absolute object-cover">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="w-full flex relative justify-center items-center lg:h-[550px] h-[950px]">
        <div class="w-full h-full absolute top-0 left-0 flex lg:flex-row flex-col items-center justify-center">
            <div class="lg:w-[50%] w-full lg:h-full h-[300px] flex items-center justify-center relative overflow-hidden">
                <img src="../assets/img/partenaire.jpg" alt="" class="w-full h-full absolute object-cover">
                <div class="w-full h-full absolute top-0 left-0 bg-gray-800/50"></div>
            </div>
            <div class="lg:w-[50%] w-full lg:h-full h-[600px] bg-[#F2F4F8]"></div>
        </div>
        <div class="container lg:max-w-[1024px] px-[20px] relative w-full flex justify-center items-center">
            <div class="w-full flex lg:flex-row flex-col items-center justify-center relative">
                <div class="lg:w-[50%] w-full relative flex items-center justify-center h-[300px]"></div>
                <div class="lg:w-[50%] w-full lg:h-[400px] h-[600px] relative pt-[20px] lg:pl-[50px]">
                    <h2 class="text-xl font-bold text-gray-600 uppercase">Types de partenariats disponibles</h2>
                    <p class="text-3xl font-bold text-gray-800 mb-[20px]">
                        Collaborez avec nous selon votre expertise
                    </p>
                    <p class="text-gray-600">
                        Nous proposons plusieurs types de partenariats adaptés à votre domaine d’activité :
                    </p>
                    <ul>
                        <li class="flex text-sm text-gray-700 items-start justify-start mb-[10px]">
                            <i class="fa-solid fa-circle-dot text-green-600 mr-[5px] mt-[2.5px]"></i>
                            <p>
                                <span class="font-bold">Partenariat technologique :</span> Idéal pour les agences et startups tech souhaitant intégrer leurs solutions à nos services.
                            </p>
                        </li>
                        <li class="flex text-sm text-gray-700 items-start justify-start mb-[10px]">
                            <i class="fa-solid fa-circle-dot text-green-600 mr-[5px] mt-[2.5px]"></i>
                            <p>
                                <span class="font-bold">Partenariat commercial :</span> Développez votre réseau en proposant nos services à vos clients tout en bénéficiant de commissions attractives.
                            </p>
                        </li>
                        <li class="flex text-sm text-gray-700 items-start justify-start mb-[10px]">
                            <i class="fa-solid fa-circle-dot text-green-600 mr-[5px] mt-[2.5px]"></i>
                            <p>
                                <span class="font-bold">Partenariat freelance :</span> Vous êtes un expert en développement, design ou marketing digital ? Rejoignez notre réseau de talents et travaillez sur des projets innovants.
                            </p>
                        </li>
                        <li class="flex text-sm text-gray-700 items-start justify-start mb-[10px]">
                            <i class="fa-solid fa-circle-dot text-green-600 mr-[5px] mt-[2.5px]"></i>
                            <p>
                                <span class="font-bold">Partenariat institutionnel :</span> Collaborons avec des ONG, des organisations gouvernementales et des incubateurs pour promouvoir la digitalisation en Afrique.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="w-full flex relative justify-center items-center my-[100px]">
        <div class="container lg:max-w-[1024px] w-full relative flex flex-col justify-center items-center px-[20px]">
            <h2 class="text-xl font-bold text-gray-400">Nos partenaires actuels</h2>
            <p class="text-3xl font-bold text-gray-800 text-center">
                Ils nous font confiance 
            </p>
            <p class="w-full max-w-[850px] my-[20px] text-center text-sm text-gray-600">
                Découvrez les entreprises, startups et institutions qui nous font confiance et qui bénéficient déjà de notre expertise digitale. Nous avons collaboré avec des acteurs de divers secteurs pour créer des solutions innovantes et adaptées à leurs besoins.
            </p>
            <div class="w-full flex items-center justify-between flex-wrap relative">
                <div class="lg:w-[calc(25%-20px)] w-[calc(50%-20px)] md:h-[200px] h-[120px] flex items-center justify-center rounded-[10px] relative overflow-hidden shadow-4xl my-[20px]">
                    <img src="../assets/img/logo/ironsecur_logo.png" alt="" class="w-auto h-[80px] absolute object-cover">
                </div>
                <div class="lg:w-[calc(25%-20px)] w-[calc(50%-20px)] md:h-[200px] h-[120px] flex items-center justify-center rounded-[10px] relative overflow-hidden shadow-4xl my-[20px]">
                    <img src="../assets/img/logo/revixo_logo.jpg" alt="" class="w-auto h-[80px] absolute object-cover">
                </div>
                <div class="lg:w-[calc(25%-20px)] w-[calc(50%-20px)] md:h-[200px] h-[120px] flex items-center justify-center rounded-[10px] relative overflow-hidden shadow-4xl my-[20px]">
                    <img src="../assets/img/logo/code_logo.jpg" alt="" class="w-auto h-[80px] absolute object-cover">
                </div>
                <div class="lg:w-[calc(25%-20px)] w-[calc(50%-20px)] md:h-[200px] h-[120px] flex items-center justify-center rounded-[10px] relative overflow-hidden shadow-4xl my-[20px]">
                    <img src="../assets/img/logo/pommecity_logo.png" alt="" class="w-auto h-[80px] absolute object-cover">
                </div>
            </div>
        </div>
    </section>
    <section class="w-full flex relative justify-center items-center lg:h-[350px] h-[850px]">
        <div class="w-full h-full absolute top-0 left-0 flex lg:flex-row flex-col items-center justify-center">
            <div class="lg:w-[50%] w-full lg:h-full h-[400px] bg-[#F2F4F8]"></div>
            <div class="lg:w-[50%] w-full lg:h-full h-[450px] flex items-center justify-center relative overflow-hidden">
                <img src="../assets/img/partenaire.jpg" alt="" class="w-full h-full absolute object-cover">
                <div class="w-full h-full absolute top-0 left-0 bg-gray-800/90"></div>
            </div>
        </div>
        <div class="container lg:max-w-[1024px] px-[20px] relative w-full flex justify-center items-center">
            <div class="w-full flex items-center justify-center lg:flex-row flex-col relative">
                <div class="lg:w-[50%] w-full relative lg:pr-[50px] h-[350px] flex flex-col justify-center">
                    <h2 class="text-xl font-bold text-gray-600 uppercase">Comment devenir partenaire ?</h2>
                    <p class="text-3xl font-bold text-gray-800 mb-[20px]">
                        Rejoignez l’aventure Vaybe dès aujourd’hui !
                    </p>
                    <p class="text-gray-600">
                        Prêt à collaborer avec Vaybe et à booster la transformation digitale en Afrique ? Rien de plus simple !
                    </p>
                    <button @click="togglePartenariat()" class="p-[10px] mt-[40px] rounded-[5px] text-sm font-bold text-white bg-[#014AA9]">
                        Devenir Partenaire Maintenant
                    </button>
                </div>
                <div class="lg:w-[50%] w-full relative h-[450px] flex items-center justify-center lg:pl-[50px] flex-col">
                    <div class="flex w-full h-auto relative items-center justify-center my-[10px]">
                        <div class="w-[80px] min-w-[80px] h-full bg-[#014AA9] flex items-center justify-center relative text-4xl font-bold text-white">01</div>
                        <div class="w-full bg-white h-full p-[10px]">
                            <h1 class="text-sm font-bold text-gray-800">Remplissez notre formulaire de partenariat</h1>
                            <p class="text-sm text-gray-600">En nous indiquant votre domaine d’expertise et vos attentes.</p>
                        </div>
                    </div>
                    <div class="flex w-full h-auto relative items-center justify-center my-[10px]">
                        <div class="w-[80px] min-w-[80px] h-full bg-[#014AA9] flex items-center justify-center relative text-4xl font-bold text-white">02</div>
                        <div class="w-full bg-white h-full p-[10px]">
                            <h1 class="text-sm font-bold text-gray-800">Échangeons ensemble</h1>
                            <p class="text-sm text-gray-600">Pour définir la meilleure collaboration possible.</p>
                        </div>
                    </div>
                    <div class="flex w-full h-auto relative items-center justify-center my-[10px]">
                        <div class="w-[80px] min-w-[80px] h-full bg-[#014AA9] flex items-center justify-center relative text-4xl font-bold text-white">03</div>
                        <div class="w-full bg-white h-full p-[10px]">
                            <h1 class="text-sm font-bold text-gray-800">Lançons notre partenariat</h1>
                            <p class="text-sm text-gray-600">Et développons des solutions digitales performantes ensemble !</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-if="openPartenariat" class="w-full h-[calc(100vh-70px)] z-50 fixed top-[70px] left-0 bg-gray-800/90 flex items-center p-[20px] justify-center">
        <div class="w-full max-w-[550px] h-full overflow-y-auto parent max-h-full relative bg-white rounded-[10px] pt-0 p-[20px]">
            <div class="w-full border-b border-gray-400 sticky top-0 z-40 bg-white flex justify-start items-center py-[20px]">
                <button @click="togglePartenariat()" class="w-[30px] h-[30px] mr-[20px] rounded-full relative flex items-center justify-center bg-gray-100 text-gray-800 text-sm font-bold">
                    <i class="fa-solid fa-arrow-left"></i>
                </button>
                <h2 class="text-sm font-bold text-gray-800">Formulaire de partenariat</h2>
            </div>
            <form @submit.prevent="sendPartenaire()" class="w-full relative py-[20px]">
                <div class="my-[15px] relative">
                    <label for="nom" class="text-sm font-bold text-gray-800 mb-[5px]">Nom de l'entreprise / Nom du partenaire <span class="text-red-600 font-bold">*</span></label>
                    <input type="text" id="nom" v-model="nom" required class="w-full p-[10px] rounded-[5px] outline-none text-sm border border-gray-300" placeholder="Ex: Vaybe">
                </div>
                <div class="my-[15px] relative">
                    <label for="secteur" class="text-sm font-bold text-gray-800 mb-[5px]">Secteur d'activité <span class="text-red-600 font-bold">*</span></label>
                    <input type="text" id="secteur" v-model="secteur" required class="w-full p-[10px] rounded-[5px] outline-none text-sm border border-gray-300" placeholder="Ex: Informatique & technologie">
                </div>
                <div class="my-[15px] relative">
                    <label for="reseau" class="text-sm font-bold text-gray-800 mb-[5px]">Site web / Réseaux sociaux</label>
                    <input type="text" id="reseau" v-model="reseau" class="w-full p-[10px] rounded-[5px] outline-none text-sm border border-gray-300" placeholder="Ex: https://vaybe.tech">
                </div>
                <div class="my-[15px] relative">
                    <label for="email" class="text-sm font-bold text-gray-800 mb-[5px]">Email <span class="text-red-600 font-bold">*</span></label>
                    <input type="text" id="email" v-model="email" required class="w-full p-[10px] rounded-[5px] outline-none text-sm border border-gray-300" placeholder="Ex: contact@vaybe.tech">
                </div>
                <div class="my-[15px] relative">
                    <label for="phone" class="text-sm font-bold text-gray-800 mb-[5px]">Numéro <span class="text-red-600 font-bold">*</span></label>
                    <input type="text" id="phone" v-model="numero" required class="w-full p-[10px] rounded-[5px] outline-none text-sm border border-gray-300" placeholder="Ex: 2290141787700">
                </div>
                <div class="my-[15px] relative">
                    <label for="type" class="text-sm font-bold text-gray-800 mb-[5px]">Type de partenariat souhaité <span class="text-red-600 font-bold">*</span></label>
                    <input type="text" id="type" v-model="type" required class="w-full p-[10px] rounded-[5px] outline-none text-sm border border-gray-300" placeholder="Ex: Sponsoring">
                </div>
                <div class="my-[15px] relative">
                    <label for="message" class="text-sm font-bold text-gray-800 mb-[5px]">Message détaillé <span class="text-red-600 font-bold">*</span></label>
                    <textarea type="text" id="message" v-model="message" required class="w-full p-[10px] rounded-[5px] outline-none text-sm border h-[225px] resize-none border-gray-300" placeholder="Pourquoi voulez vous devenir partenaires ? Qu'attendez vous de nous ?"></textarea>
                </div>
                <div class="my-[15px] relative">
                    <button type="submit" class="bg-[#014AA9] h-[45px] flex items-center justify-center relative w-full p-[10px] rounded-[5px] cursor-pointer duration-300 hover:bg-blue-900 ease-in text-sm font-bold text-white">
                        <div v-if="isLoading" class="three-body1">
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                        </div>
                        <span v-else>Soumettre</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <FooterComponent/>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { ref } from 'vue'
import axios from 'axios'

const openPartenariat = ref(false)
const nom = ref("")
const type = ref("")
const numero = ref(null)
const reseau = ref("")
const message = ref("")
const secteur = ref("")
const email = ref("")
const isLoading = ref(false)
const errorMessage = ref("")
const successMessage = ref("")

function togglePartenariat () {
    openPartenariat.value = !openPartenariat.value
}

const sendPartenaire = async() => {
    try {
        isLoading.value = true
        await axios.post('/api/partnerships', {
            sector: secteur.value,
            name: nom.value,
            website: reseau.value,
            phone: numero.value,
            email: email.value,
            partnershipType: type.value,
            message: message.value
        })
        successMessage.value = "Votre demande est envoyé avec succès !"
        hideSuccessMessageAfterDelay()
        nom.value = ""
        secteur.value = ""
        numero.value = ""
        email.value = ""
        message.value = ""
        type.value = ""
        reseau.value = ""
        openPartenariat.value = false
    } catch {
        errorMessage.value = "Erreur lors de l'envoie de la demande."
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};


</script>

<style lang="css" scoped>

</style>