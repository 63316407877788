<template>
    <HeaderComponent/>
    <section id="home" class="bg-[#014AA9] py-[100px] w-screen relative flex justify-center items-center">
        <div class="container lg:max-w-[1024px] w-full relative flex px-[20px] justify-center items-center">
            <div class="w-full flex lg:flex-row flex-col justify-center items-center relative">
                <div id="uniqueMobile" class="lg:w-6/12 flex lg:hidden p-[10px] w-full relative justify-center items-center">
                    <img src="../assets/img/Vaybe/home.jpg" alt="" class="w-[90%] z-[2] relative">
                </div>
                <div class="lg:w-6/12 w-full relative mt-[20px] lg:mt-0 p-[10px]">
                    <h1 class="flex flex-col">
                        <span class="lg:text-[68px] text-3xl lg:leading-[60px] font-bold font-mono text-blue-100">
                            Vos prochains projets
                        </span>
                        <span class="text-white lg:text-[60px] text-3xl lg:leading-[60px] font-bold font-mono">
                            commencent ici
                        </span>
                    </h1>
                    <p class="my-[20px] text-gray-100 text-xl">
                        Nous aidons les entreprises et les experts externe à faire équipe plus facilement que jamais
                    </p>
                    <div class="md:mt-[50px] mt-[20px] justify-between md:justify-start flex items-center">
                        <button @click="goToDevisPage()" class="md:p-[20px] p-[20px] rounded-[5px] ml-0 m-[10px] font-semibold text-center bg-[#FFF] text-[#014AA9]">Demander un devis</button>
                        <button @click="goToPartenariatPage()" class="md:p-[20px] p-[20px] rounded-[5px] ml-0 m-[10px] font-semibold text-center text-[#2C2C2C] bg-[#F7B801]">Devenir partenaire</button>
                    </div>
                </div>
                <div id="unique" class="lg:w-6/12 hidden lg:flex p-[10px] w-full relative justify-center items-center">
                    <img src="../assets/img/Vaybe/home.jpg" alt="" class="w-[80%] z-[2] relative">
                </div>
            </div>
        </div>
    </section>
    <section class="py-[50px] bg-white flex justify-center items-center w-full relative">
        <div class="container w-full relative px-[20px] lg:max-w-[1024px] flex justify-center items-center flex-col">
            <div class="w-full relative flex md:flex-row flex-col-reverse">
                <div class="md:w-6/12 w-full mt-[10px] md:mt-0 relative flex justify-center items-center md:pr-[10px]">
                    <div id="f" class="z-10 md:w-[80%] w-full md:h-[300px] rounded-[10px] overflow-hidden relative h-[250px]">
                        <img src="../assets/img/solution.jpg" alt="" class="w-full h-full absolute object-cover object-center">
                    </div>
                </div>
                <div class="md:w-6/12 w-full mb-[10px] md:mb-0 md:pl-[10px]">
                    <h2 class="text-gray-800 font-bold text-3xl capitalize">Nous façonnons la solution parfaite pour <span class="text-[#014AA9]">Entreprise</span></h2>
                    <p class="my-[15px] text-sm text-gray-700">
                        Chaque entreprise est unique, et nous créons des solutions qui le reflètent. Votre vision guide notre savoir-faire pour des résultats sur mesure. Ensemble, donnons forme à l’innovation qui propulsera votre entreprise.
                    </p>
                    <div class="w-full p-[15px] border-l-2 border-[#014AA9]">
                        <h2 class="text-gray-800 font-bold">Innovation Inspirée par l'Afrique, Créée pour le Monde</h2>
                        <span class="text-[#014AA9] font-bold">Depuis 2023</span>
                    </div>
                </div>
            </div>
            <div class="w-full relative flex my-[20px] justify-between items-center flex-wrap">
                <div class="flex items-center my-[10px] mr-[5px] w-[calc(50%-10px)] md:w-auto">
                    <i class="fa-solid fa-chart-simple text-3xl text-[#014AA9] mt-[5px]"></i>
                    <div class="ml-[10px]">
                        <h3 class="text-gray-800 font-bold text-xl">1,000+</h3>
                        <p class="text-xs font-bold mt-[-5px] text-gray-600">Utilisateurs actifs</p>
                    </div>
                </div>
                <div class="flex items-center my-[10px] ml-[5px] w-[calc(50%-10px)] md:w-auto">
                    <i class="fa-solid fa-list-check text-3xl text-[#F7B801] mt-[5px]"></i>
                    <div class="ml-[10px]">
                        <h3 class="text-gray-800 font-bold text-xl">25+</h3>
                        <p class="text-xs font-bold mt-[-5px] text-gray-600">Tâches accomplies</p>
                    </div>
                </div>
                <div class="flex items-center my-[10px] mr-[5px] w-[calc(50%-10px)] md:w-auto">
                    <i class="fa-solid fa-chart-pie text-3xl text-[#F7B801] mt-[5px]"></i>
                    <div class="ml-[10px]">
                        <h3 class="text-gray-800 font-bold text-xl">10+</h3>
                        <p class="text-xs font-bold mt-[-5px] text-gray-600">Projets terminés</p>
                    </div>
                </div>
                <div class="flex items-center my-[10px] ml-[5px] w-[calc(50%-10px)] md:w-auto">
                    <i class="fa-solid fa-desktop text-3xl text-[#4FA3E5] mt-[5px]"></i>
                    <div class="ml-[10px]">
                        <h3 class="text-gray-800 font-bold text-xl">250+</h3>
                        <p class="text-xs font-bold mt-[-5px] text-gray-600">Retour positifs</p>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <section class="py-[50px] bg-white flex justify-center items-center w-full relative">
        <div class="container w-full relative px-[20px] lg:max-w-[1024px] flex justify-center items-center flex-col">
            <div class="w-full relative">
                <div class="w-full relative md:flex justify-between items-end">
                    <h2 class="font-bold text-gray-800 text-3xl capitalize md:w-[400px]">
                        Les <span class="text-[#F7B801]"> domaines </span> dans lesquels nous servons
                    </h2>
                    <button @click="goToServicesPage()" class="p-[10px] bg-[#F7B801] mt-[20px] md:mt-0 text-[#2C2C2C] text-sm font-bold rounded-[5px] flex justify-center text-center flex-wrap items-center">
                        Tous nos services
                        <i class="fa-solid fa-circle-chevron-right text-[8px] mt-[2px] ml-[5px]"></i>
                    </button>
                </div>
                <div class="my-[25px] md:mt-[50px] flex flex-col flex-wrap md:flex-row lg:justify-between justify-center items-center w-full relative">
                    <div class="md:w-[calc(100%/3-20px)] md:min-w-[calc(300px)] w-full h-[370px] rounded-[10px] my-[20px] md:mx-[10px] lg:mx-0 p-[25px] bg-[#2C2C2C]">
                        <h2 class="text-xl font-bold text-[#F7B801] uppercase">
                            Développement de plateformes web sur mesure
                        </h2>
                        <p class="text-xs font-semibold my-[20px] text-white">
                            Transformez vos idées en réalité avec une plateforme web qui s’adapte parfaitement à vos objectifs commerciaux.
                        </p>
                        <div class="flex flex-col">
                            <div class="flex items-center my-[5px] relative text-xs">
                                <i class="fa-solid fa-check mr-[10px] text-[#F7B801]"></i>
                                <p class="text-[#F2F4F8]">Solutions 100% personnalisées</p>
                            </div>
                            <div class="flex items-center my-[5px] relative text-xs">
                                <i class="fa-solid fa-check mr-[10px] text-[#F7B801]"></i>
                                <p class="text-[#F2F4F8]">Design adapté aux besoins du client</p>
                            </div>
                            <div class="flex items-center my-[5px] relative text-xs">
                                <i class="fa-solid fa-check mr-[10px] text-[#F7B801]"></i>
                                <p class="text-[#F2F4F8]">Plateformes évolutives et performantes</p>
                            </div>
                        </div>
                        <button class="p-[10px] rounded-[5px] bg-[#F7B801] text-[#2C2C2C] text-xs font-bold mt-[10px]">
                            <a target="_blank" href="https://wa.me/22941787700?text=Bonjour%2C%20je%20suis%20intéressé%20par%20votre%20service%20de%20développement%20de%20plateformes%20web%20sur%20mesure.%20J%27aimerais%20en%20savoir%20plus%20sur%20vos%20offres%20et%20discuter%20de%20mon%20projet.%20Pouvez-vous%20me%20donner%20plus%20d%27informations%20%3F%20Merci%20!">
                                Choisir l'offre
                            </a>
                        </button>
                    </div>
                    <div class="md:w-[calc(100%/3-20px)] md:min-w-[calc(300px)] w-full h-[370px] rounded-[10px] my-[20px] md:mx-[10px] lg:mx-0 p-[25px] bg-[#2C2C2C]">
                        <h2 class="text-xl font-bold text-[#F7B801] uppercase">
                            Optimisation de l'Expérience Utilisateur (UX/UI)
                        </h2>
                        <p class="text-xs font-semibold my-[20px] text-white">
                            Offrez à vos utilisateurs une expérience inégalée avec des interfaces claires, esthétiques et fonctionnelles.
                        </p>
                        <div class="flex flex-col">
                            <div class="flex items-center my-[5px] relative text-xs">
                                <i class="fa-solid fa-check mr-[10px] text-[#F7B801]"></i>
                                <p class="text-[#F2F4F8]">Expérience utilisateur fluide</p>
                            </div>
                            <div class="flex items-center my-[5px] relative text-xs">
                                <i class="fa-solid fa-check mr-[10px] text-[#F7B801]"></i>
                                <p class="text-[#F2F4F8]">Navigation intuitive et claire</p>
                            </div>
                            <div class="flex items-center my-[5px] relative text-xs">
                                <i class="fa-solid fa-check mr-[10px] text-[#F7B801]"></i>
                                <p class="text-[#F2F4F8]">Augmente l'engagement et la conversion</p>
                            </div>
                        </div>
                        <button class="p-[10px] rounded-[5px] bg-[#F7B801] text-[#2C2C2C] text-xs font-bold mt-[10px]">
                            <a target="_blank" href="https://wa.me/22941787700?text=Bonjour%2C%20je%20suis%20intéressé%20par%20votre%20service%20d%27optimisation%20de%20l%27expérience%20utilisateur%20%28UX%2FUI%29.%20J%27aimerais%20en%20savoir%20plus%20sur%20vos%20offres%20et%20voir%20comment%20vous%20pouvez%20améliorer%20mon%20projet.%20Pouvez-vous%20me%20donner%20plus%20d%27informations%20%3F%20Merci%20!">
                                Choisir l'offre
                            </a>
                        </button>
                    </div>
                    <div class="md:w-[calc(100%/3-20px)] md:min-w-[calc(300px)] w-full h-[370px] rounded-[10px] my-[20px] md:mx-[10px] lg:mx-0 p-[25px] bg-[#2C2C2C]">
                        <h2 class="text-xl font-bold text-[#F7B801] uppercase">
                            Solutions de gestion et automatisation pour entreprises
                        </h2>
                        <p class="text-xs font-semibold my-[20px] text-white">
                            Optimisez vos opérations avec des outils de gestion personnalisés et efficaces, conçus pour simplifier vos processus quotidiens.
                        </p>
                        <div class="flex flex-col">
                            <div class="flex items-center my-[5px] relative text-xs">
                                <i class="fa-solid fa-check mr-[10px] text-[#F7B801]"></i>
                                <p class="text-[#F2F4F8]">Processus simplifiés</p>
                            </div>
                            <div class="flex items-center my-[5px] relative text-xs">
                                <i class="fa-solid fa-check mr-[10px] text-[#F7B801]"></i>
                                <p class="text-[#F2F4F8]">Automatisation des tâches</p>
                            </div>
                            <div class="flex items-center my-[5px] relative text-xs">
                                <i class="fa-solid fa-check mr-[10px] text-[#F7B801]"></i>
                                <p class="text-[#F2F4F8]">Gain de temps et d’efficacité</p>
                            </div>
                        </div>
                        <button class="p-[10px] rounded-[5px] bg-[#F7B801] text-[#2C2C2C] text-xs font-bold mt-[10px]">
                            <a target="_blank" href="https://wa.me/22941787700?text=Bonjour%2C%20je%20suis%20intéressé%20par%20vos%20solutions%20de%20gestion%20et%20d%27automatisation%20pour%20entreprises.%20J%27aimerais%20en%20savoir%20plus%20sur%20vos%20offres%20et%20voir%20comment%20elles%20peuvent%20optimiser%20mes%20opérations.%20Pouvez-vous%20me%20donner%20plus%20d%27informations%20%3F%20Merci%20!">
                                Choisir l'offre
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-[50px] bg-[#F2F4F8] flex justify-center items-center w-screen">
        <div class="container lg:max-w-[1024px] lg:px-[25px] px-[10px] fles justify-center items-center w-full relative">
            <div class="w-full md:flex md:flex-row flex-col justify-center relative">
                <div class="md:w-8/12 w-full flex flex-col justify-center items-start p-[10px]">
                    <h3 class="font-bold text-3xl">
                        Gandhi
                    </h3>
                    <p class="lg:text-5xl text-3xl font-bold lg:mt-[60px] mt-[30px]">
                       ‘’ Vous devez être le changement que vous voulez voir dans ce monde 
                    </p>
                </div>
                <div class="md:w-4/12 w-full mt-[20px] md:mt-0 flex justify-center relative items-center p-[10px]">
                    <img src="../assets/img/gandhi1.png" alt="" class="w-full">
                </div>
            </div>
        </div>
    </section>
    <section class="py-[50px] bg-white flex justify-center items-center w-full relative">
        <div class="container w-full px-[20px] flex justify-center items-center lg:max-w-[1024px] relative">
            <div class="w-full flex md:flex-row flex-col justify-center items-center">
                <div class="md:w-[50%] w-full flex flex-col jus items-start relative md:pr-[50px]">
                    <h2 class="text-sm font-bold text-[#014AA9] uppercase">Ils nous font confiance</h2>
                    <p class="text-5xl font-bold text-gray-800 my-[20px]">
                        C'est génial!<br> Toujours à la hauteur de vos attentes!
                    </p>
                    <p class="text-xs font-semibold text-gray-400 md:max-w-[80%] leading-5">
                        Des services impeccables, une équipe attentive à chaque détail. Votre satisfaction est notre plus belle récompense. Découvrez pourquoi nos clients nous choisissent pour transformer leurs idées en réussite.
                    </p>
                    <div class="mt-[25px] flex items-center justify-center md:justify-start w-full">
                        <div class="flex items-center">
                            <div class="w-[5px] h-[5px] rounded-[50%] bg-gray-800 mr-[5px]"></div>
                            <div class="w-[5px] h-[5px] rounded-[50%] bg-gray-300 mr-[5px]"></div>
                            <div class="w-[5px] h-[5px] rounded-[50%] bg-gray-300 mr-[5px]"></div>
                            <div class="w-[5px] h-[5px] rounded-[50%] bg-gray-300 mr-[5px]"></div>
                            <div class="w-[5px] h-[5px] rounded-[50%] bg-gray-300 mr-[5px]"></div>
                            <div class="w-[5px] h-[5px] rounded-[50%] bg-gray-300 mr-[5px]"></div>
                            <div class="w-[5px] h-[5px] rounded-[50%] bg-gray-300 mr-[5px]"></div>
                            <div class="w-[5px] h-[5px] rounded-[50%] bg-gray-300 mr-[5px]"></div>
                        </div>
                        <i class="fa-solid fa-minus mx-[10px] mt-[2px]"></i>
                        <h2 class="text-sm font-bold text-gray-800">IronSecur</h2>
                    </div>
                </div>
                <div class="md:w-[50%] w-full flex items-center justify-center relative">
                    <div class="flex flex-col justify-start items-end">
                        <div class="w-[150px] h-[150px] bg-white shadow-4xl m-[5px] rounded-[20px] flex justify-center items-center relative">
                            <img src="../assets/img/logo/ironsecur_logo.png" alt="" class="w-[80%] absolute object-contain">
                        </div>
                        <div class="w-[70px] h-[70px] bg-white shadow-4xl m-[5px] rounded-[20px] hidden justify-center items-center relative">
                            <img src="../assets/img/logo/atl_logo.png" alt="" class="w-[80%] absolute object-contain">
                        </div>
                    </div>
                    <div class="flex flex-col justify-start items-end mt-[80px]">
                        <div class="w-[70px] h-[70px] bg-gray-800 shadow-4xl m-[5px] rounded-[20px] flex justify-center items-center relative">
                            <img src="../assets/img/logo/bet_logo.png" alt="" class="w-[80%] absolute object-contain">
                        </div>
                        <div class="w-[70px] h-[70px] bg-white shadow-4xl m-[5px] rounded-[20px] flex justify-center items-center relative">
                            <img src="../assets/img/logo/revixo_logo.jpg" alt="" class="w-[80%] absolute object-contain">
                        </div>
                        <div class="w-[70px] h-[70px] bg-black shadow-4xl m-[5px] rounded-[20px] flex justify-center items-center relative">
                            <img src="../assets/img/logo/cailledor_logo.png" alt="" class="w-[80%] absolute object-contain">
                        </div>
                    </div>
                    <div class="flex flex-col justify-start items-end mt-[80px]">
                        <div class="w-[70px] h-[70px] bg-black shadow-4xl m-[5px] rounded-[20px] flex justify-center items-center relative">
                            <img src="../assets/img/logo/code_logo.jpg" alt="" class="w-[80%] absolute object-contain">
                        </div>
                        <div class="w-[70px] h-[70px] bg-white shadow-4xl m-[5px] rounded-[20px] hidden justify-center items-center relative">
                            <img src="../assets/img/logo/sterne_logo.png" alt="" class="w-[80%] absolute object-contain">
                        </div>
                        <div class="w-[70px] h-[70px] bg-white shadow-4xl m-[5px] rounded-[20px] flex justify-center items-center relative">
                            <img src="../assets/img/logo/pommecity_logo.png" alt="" class="w-[60%] absolute object-contain">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-[50px] bg-[#2C2C2C] flex justify-center items-center w-full relative">
        <div class="container w-full px-[20px] flex flex-col justify-center items-center lg:max-w-[1024px] relative">
            <div class="w-full mb-[25px] md:mb-[50px] flex md:flex-row flex-col items-start justify-center md:justify-between relative">
                <div class="w-full md:w-auto md:flex hidden flex-col">
                    <p class="md:text-xl text-xs font-bold text-[#4FA3E5]">Présence actuelle et zones d'opportunité</p>
                    <h2 class="md:text-2xl text-sm font-bold text-white">Marchés ciblés</h2>
                </div>
                <div class="md:max-w-[400px] mt-[10px] md:mt-0 w-full md:block flex flex-col items-center justify-center">
                    <p class="md:text-sm text-[10px] px-[20px] md:px-0 text-center md:text-left font-semibold text-[#F2F4F8]">
                        Présents aujourd’hui, partout demain ! Déjà présents sur ces territoires, avec une ambition d’expansion constante.
                    </p>
                    <button @click="goToAboutPage()" class="text-[#014AA9] p-[10px] rounded-[5px] bg-[#FFF] mt-[10px] text-sm font-bold">En savoir plus</button>
                </div>
            </div>
            <div id="map"></div>
        </div>
    </section>
    <section class="py-[50px] bg-white hidden justify-center items-center w-full relative">
        <div class="container w-full px-[20px] flex justify-center items-center max-w-[1024px] relative">
            <div class="w-full flex flex-col items-center justify-center relative">
                <h2 class="text-gray-800 font-bold text-sm">Témoignages</h2>
                <h2 class="text-xl md:text-3xl text-center capitalize my-[10px] text-[#014AA9] font-bold">Ce qu'en disent nos clients</h2>
                <div class="w-[85%] flex justify-center items-center relative py-[50px]">
                    <div class="md:w-[25%] flex flex-col justify-between items-center h-[200px] py-[25px] relative">
                        <div class="w-full flex items-center justify-end relative">
                            <div class="w-[70px] h-[70px] rounded-[50%] overflow-hidden flex items-center justify-center relative">
                                <img src="../assets/img/gandhi.png" alt="" class="w-full h-full absolute object-cover rounded-[50%]">
                            </div>
                        </div>
                        <div class="w-full flex items-center justify-start relative">
                            <div class="w-[70px] h-[70px] rounded-[50%] overflow-hidden flex items-center justify-center relative">
                                <img src="../assets/img/gandhi.png" alt="" class="w-full h-full absolute object-cover rounded-[50%]">
                            </div>
                        </div>
                    </div>
                    <div class="md:w-[50%] flex items-center justify-center h-[200px] relative">
                        <div class="w-[100px] h-[100px] rounded-[50%] p-[20px] overflow-hidden flex items-center justify-center relative border-2 border-gray-500">
                            <img src="../assets/img/gandhi.png" alt="" class="w-[calc(100%-20px)] h-[calc(100%-20px)] absolute object-cover rounded-[50%]">
                        </div>
                    </div>
                    <div class="md:w-[25%] flex flex-col justify-between items-center h-[200px] py-[25px] relative">
                        <div class="w-full flex items-center justify-end relative">
                            <div class="w-[70px] h-[70px] rounded-[50%] overflow-hidden flex items-center justify-center relative">
                                <img src="../assets/img/gandhi.png" alt="" class="w-full h-full absolute object-cover rounded-[50%]">
                            </div>
                        </div>
                        <div class="w-full flex items-center justify-start relative">
                            <div class="w-[70px] h-[70px] rounded-[50%] overflow-hidden flex items-center justify-center relative">
                                <img src="../assets/img/gandhi.png" alt="" class="w-full h-full absolute object-cover rounded-[50%]">
                            </div>
                        </div>
                    </div>
                </div>
                <p class="max-w-[400px] text-center text-sm font-semibold text-gray-600">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat nam magnam explicabo asperiores soluta eveniet quod sapiente consequuntur ab animi, eligendi rem.
                </p>
                <h3 class="text-xl font-bold text-gray-800 my-[20px]">Mickaël AGOSSA</h3>
                <div class="flex items-center">
                    <div class="w-[10px] h-[10px] rounded-[50%] bg-gray-300 mx-[5px]"></div>
                    <div class="w-[10px] h-[10px] rounded-[50%] bg-gray-300 mx-[5px]"></div>
                    <div class="w-[10px] h-[10px] rounded-[50%] bg-gray-800 mx-[5px]"></div>
                    <div class="w-[10px] h-[10px] rounded-[50%] bg-gray-300 mx-[5px]"></div>
                    <div class="w-[10px] h-[10px] rounded-[50%] bg-gray-300 mx-[5px]"></div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-[50px] bg-white flex justify-center items-center w-full relative">
        <div class="container w-full px-[20px] flex justify-center items-center max-w-[1024px] relative">
            <div class="w-full flex flex-col items-center justify-center relative">
                <div class="w-full flex md:flex-row flex-col justify-center overflow-x-auto parent items-center relative py-[50px]">
                    <div class="md:w-[50%] w-full">
                        <h2 class="text-5xl text-left capitalize my-[10px] text-[#014AA9] font-bold">
                            Ce qu'en disent <br> nos clients
                        </h2>
                        <p class="text-sm text-gray-600 font-semibold pr-[50px] mt-[20px]">
                            Décrouvez ce que nos clients pensent de leur expérience avec Vaybe.
                        </p>
                        <p class="text-sm text-gray-600 font-semibold pr-[50px] mb-[20px]">
                            Leurs avis témoignent de la qualité et de l'impact de nos services.
                        </p>
                        <button class="p-[10px] rounded-[5px] bg-[#014AA9] text-white text-sm font-bold">
                            Donner votre Avis
                        </button>
                    </div>
                    <div class="md:w-[50%] w-full px-[20px] mt-[20px]">
                        <div
                        v-for="(testimonial, index) in testimonials"
                        :key="testimonial.id"
                        class="my-[15px] flex flex-row justify-start items-center rounded-[5px] h-auto p-[20px] relative"
                        :class="{'border-l-[5px] border-[#014AA9] shadow-6xl md:ml-[-50px] ml-[-15px]': testimonial.id == 2, 'border border-gray-300 border-l-[5px] mr-[-15px] md:mr-0': testimonial.id != 2}"
                        @click="changeTestimonial(index)"
                        >
                            <div class="w-[60px] min-w-[60px] mr-[20px] h-[60px] rounded-full flex items-center justify-center relative overflow-hidden">
                                <img :src="testimonial.image" alt="" class="w-full h-full absolute object-cover rounded-[50%]"/>
                            </div>
                            <div class="flex flex-col w-full relative">
                                <h3 class="font-bold text-sm text-gray-800">{{ testimonial.name }}</h3>
                                <p class="text-sm text-gray-500">
                                    {{ testimonial.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-[50px] bg-white hidden justify-center items-center w-full relative">
        <div class="container w-full px-[20px] flex flex-col justify-center items-center lg:max-w-[1024px] relative">
            <div class="w-full flex flex-wrap items-center md:justify-between justify-center relative">
                <router-link :to="'/blog/'+ article.link" v-for="(article, index) in articles" :key="index" class="lg:w-[calc(100%/3-20px)] md:w-[calc(100%/2-15px)] w-full md:my-[15px] my-[10px] h-[350px] shadow-4xl rounded-[10px] p-[15px] cursor-pointer">
                    <div class="h-[125px] w-full rounded-[10px] flex justify-center items-center relative overflow-hidden">
                        <img :src="article.image" alt="" class="w-full h-full absolute object-cover object-center">
                        <h3 class="px-[10px] py-[5px] rounded-[5px] bg-white text-xs font-bold text-gray-800 text-center absolute left-[10px] bottom-[10px]">
                            {{ article.categorie }}
                        </h3>
                    </div>
                    <div class="w-full relative flex flex-col py-[10px]">
                        <div class="flex justify-between items-center text-[10px] font-semibold text-gray-500">
                            <p>
                                <i class="fa-solid fa-heart text-red-600 mr-[5px]"></i>
                                <span>{{ article.like }}</span>
                            </p>
                            <p>
                                <i class="fa-regular fa-eye mr-[5px]"></i>
                                <span>{{ article.view }}</span>
                            </p>
                        </div>
                        <h3 class="my-[15px] clamp text-sm font-bold text-gray-800">
                            {{ article.title }}
                        </h3>
                        <p class="line line-clamp-3 text-xs text-gray-600 font-semibold">
                            {{ article.content }}
                        </p>
                        <div class="mt-[15px] flex w-full relative justify-start items-center">
                            <div class="w-[30px] h-[30px] rounded-[50%] flex justify-center items-center relative overflow-hidden">
                                <img :src="article.profile" alt="" class="w-full h-full absolute object-cover object-center rounded-[50%]">
                            </div>
                            <div class="ml-[10px] flex flex-col">
                                <h3 class="text-xs font-bold text-gray-800">Mme. {{ article.autor }}</h3>
                                <p class="text-[10px] text-gray-500">
                                    <i class="fa-solid fa-clock-rotate-left mr-[5px]"></i>
                                    <span>{{ article.date }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </router-link>
                <div class="md:w-[calc(100%/3-20px)] hidden w-full my-[10px] h-[350px] bg-[#2C2C2C] shadow-4xl rounded-[10px] p-[15px] cursor-pointer">
                    
                </div>
            </div>
            <div class="mt-[25px] w-full relative flex items-center justify-center">
                <button class="p-[10px] rounded-[5px] bg-[#2C2C2C] font-bold text-sm text-[#F2F4F8]">Lire plus d'article</button>
            </div>
        </div>
    </section>
    <section class="py-[60px] bg-gradient-to-r from-blue-100 to-white hidden justify-center items-center w-full relative">
        <div class="container w-full px-[20px] flex justify-center items-center max-w-[1024px] relative">
            <div class="w-full rounded-[30px] bg-white shadow-lg md:h-[250px] h-[320px] flex flex-col justify-center items-center p-[40px] transition duration-300 ease-in-out transform">
                <h3 class="text-gray-800 md:text-2xl text-xl font-bold max-w-[600px] text-center leading-tight">
                    Rejoignez notre <span class="text-[#014AA9]">Newsletter</span> et restez informé des dernières nouveautés & astuces pour booster votre activité.
                </h3>
                <div class="mt-[25px] flex w-full relative max-w-[450px]">
                    <input type="email" name="email" id="email" placeholder="Votre adresse e-mail" class="w-full p-[12px] py-[18px] text-base outline-none pl-[40px] pr-[150px] font-medium text-gray-700 rounded-[10px] shadow-md bg-gray-50 focus:bg-white transition duration-200 ease-in-out">
                    <label for="email" class="absolute top-[15px] left-[10px] text-gray-400 text-xl">
                        <i class="fa-solid fa-envelope"></i>
                    </label>
                    <button class="p-[10px] absolute top-[8px] right-[8px] text-white text-base font-bold rounded-[5px] bg-[#014AA9] hover:bg-blue-500 transition duration-300 ease-in-out">
                        <i class="fa-solid fa-paper-plane text-sm mr-[8px]"></i>
                        S'abonner
                    </button>
                </div>
            </div>
        </div>
    </section>
    <section class="py-10 px-4">
        <h2 class="text-3xl font-bold text-center text-[#014AA9] mb-6">F.A.Q</h2>
        <div class="max-w-[850px] mx-auto space-y-4">
            <div
            v-for="(item, index) in faqItems"
            :key="index"
            class="border rounded-[5px] overflow-hidden bg-white"
            >
                <div
                class="flex justify-between items-center px-4 py-3 cursor-pointer"
                :class="{ 'bg-gray-100': item.isOpen }"
                @click="toggleFAQ(index)"
                >
                    <h3 class="text-lg font-semibold text-gray-800">
                        {{ item.question }}
                    </h3>
                    <div class="text-gray-500">
                        <span v-if="item.isOpen">−</span>
                        <span v-else>+</span>
                    </div>
                </div>
                <div
                v-if="item.isOpen"
                class="px-4 py-3 text-gray-600 text-sm overflow-hidden transition-all duration-300"
                >
                    {{ item.answer }}
                </div>
            </div>
        </div>
    </section>
    <FooterComponent/>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import VaybeLogo from '@/components/VaybeLogo.vue'
import { useRouter } from "vue-router";
import { ref, onMounted, onUnmounted } from 'vue';

const faqItems = ref([
  {
    question: "Où êtes-vous situés et travaillez-vous avec des clients à l’international ?",
    answer: "Vaybe est une startup basée au Bénin. Nous travaillons avec des clients de tous horizons, que ce soit au niveau local, régional ou international. Grâce aux outils digitaux, nous collaborons facilement avec des entreprises partout dans le monde.",
    isOpen: false,
  },
  {
    question: "Combien de temps faut-il pour développer un site web ou une application mobile ?",
    answer: "Le délai de développement dépend de la complexité du projet. Un site web vitrine peut être livré en 2 à 4 semaines, tandis qu’une application mobile ou un site e-commerce peut prendre plusieurs mois. Nous fournissons un calendrier détaillé dès le début du projet.",
    isOpen: false,
  },
  {
    question: "Offrez-vous des services de maintenance après la création du site ou de l’application ?",
    answer: "Oui, nous proposons des services de maintenance pour assurer le bon fonctionnement de votre site ou application. Cela inclut les mises à jour, la correction de bugs et l’optimisation des performances.",
    isOpen: false,
  },
  {
    question: "Que comprend la création d’une identité visuelle ?",
    answer: "Notre service d’identité visuelle inclut la conception de logo, charte graphique, typographie, palette de couleurs, cartes de visite, et supports de communication pour assurer une image cohérente et professionnelle.",
    isOpen: false,
  },
  {
    question: "Quels sont vos tarifs pour la création d’un site web ou d’une application ?",
    answer: "Nos tarifs varient en fonction des besoins du projet. Nous proposons des solutions abordables adaptées aux petites entreprises. Contactez-nous pour un devis personnalisé.",
    isOpen: false,
  },
  {
    question: "Proposez-vous des facilités de paiement pour les petites entreprises ?",
    answer: "Oui, nous permettons un paiement en plusieurs échéances afin d’aider les petites et micro-entreprises à accéder à nos services.",
    isOpen: false,
  },
  {
    question: "Quels types de conseils proposez-vous pour la transformation digitale ?",
    answer: "Nous accompagnons les entreprises dans leur digitalisation en proposant des stratégies adaptées, incluant la mise en place d’un site web performant, l’optimisation des réseaux sociaux, et l’automatisation des processus internes.",
    isOpen: false,
  },
  {
    question: "Pouvez-vous aider les entreprises sans expérience digitale ?",
    answer: "Absolument ! Nous aidons les entreprises, même sans expérience digitale, à faire leurs premiers pas en ligne en proposant des solutions simples et un accompagnement personnalisé.",
    isOpen: false,
  },
  {
    question: "Combien de temps faut-il pour commencer un projet après avoir pris contact ?",
    answer: "Tout dépend de la complexité du projet et de notre disponibilité. En général, nous pouvons démarrer dans les 48 à 72 heures après validation du devis.",
    isOpen: false,
  },
  {
    question: "Fournissez-vous un support client après la fin du projet ?",
    answer: "Oui, nous restons à votre disposition après la livraison pour toute assistance technique, formation ou mise à jour nécessaire.",
    isOpen: false,
  },
  {
    question: "Qui sera propriétaire du site web ou de l’application après sa livraison ?",
    answer: "Vous serez propriétaire à 100 % de votre site web ou application. Nous vous fournissons tous les fichiers et accès nécessaires.",
    isOpen: false,
  },
  {
    question: "Offrez-vous des formations pour utiliser les solutions que vous développez ?",
    answer: "Oui, nous proposons des formations adaptées pour permettre à nos clients de gérer efficacement leurs sites ou applications après leur création.",
    isOpen: false,
  },
  {
    question: "Comment Vaybe soutient-il les petites entreprises au Bénin et en Afrique ?",
    answer: "Nous offrons des tarifs abordables, des facilités de paiement et des conseils stratégiques pour permettre aux petites entreprises de se digitaliser sans contrainte.",
    isOpen: false,
  },
  {
    question: "Quelles actions prenez-vous pour promouvoir les talents africains dans le digital ?",
    answer: "Nous mettons en avant les compétences des jeunes africains en collaborant avec eux sur nos projets, en leur offrant des opportunités de formation et en valorisant leur travail à l’échelle internationale.",
    isOpen: false,
  },
]);

const toggleFAQ = (index) => {
  faqItems.value[index].isOpen = !faqItems.value[index].isOpen;
};

const testimonials = ref([
  {
    id: 1,
    image: './img/Logo-vaybe.png',
    text: 'Vaybe a transformé notre présence en ligne avec une solution rapide et sécurisée. Une équipe au top du début à la fin !',
    name: 'Alex T., entrepreneur',
  },
  {
    id: 2,
    image: './img/Logo-vaybe.png',
    text: 'Enfin un partenaire qui comprend nos besoins tech et livre des résultats impeccables. Efficacité et créativité réunies !',
    name: 'Sarah K., fondatrice de startup',
  },
  {
    id: 3,
    image: './img/Logo-vaybe.png',
    text: 'Vaybe a su créer exactement ce qu’on imaginait !',
    name: 'Marie P., responsable marketing',
  }
]);

/* const currentIndex = ref(0);
const intervalId = ref(null);

const changeTestimonial = (index) => {
  currentIndex.value = index;
};

const startAutoSwiper = () => {
  intervalId.value = setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % testimonials.value.length;
  }, 5000);
};

const stopAutoSwiper = () => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
    intervalId.value = null;
  }
};

onMounted(() => {
  startAutoSwiper();
}); */
/* 
onUnmounted(() => {
  stopAutoSwiper();
}); */

const router = useRouter()

function goToDevisPage() {
    router.push("/devis")
}
function goToPartenariatPage() {
    router.push("/partenaires")
}
function goToServicesPage() {
    router.push("/services")
}
function goToAboutPage() {
    router.push("/about")
}

const articles = ref([
    {
        link: 'liste-des-meilleurs-montres-de-chez-dior-a-moins-de-200€',
        image: './img/montre.jpeg',
        categorie: 'Montre de luxe',
        title: 'Liste des meilleurs montres de chez Dior à moins de 200€',
        content: 'Si vous voulez vous démarquez du lot avec des montres de luxe et tout ça sans se ruiner, vous êtes au bon endroit. Ici nous vous présentons la liste des meilleurs montre Dior à moins de 200€',
        like: '99k',
        view: '102k',
        date: '10 juin 2024',
        profile: './img/profile.jpeg',
        autor: 'TOSSOU Charbelle'
    },
    {
        link: 'liste-des-meilleurs-montres-de-chez-dior-a-moins-de-200€',
        image: './img/montre.jpeg',
        categorie: 'Montre de luxe',
        title: 'Liste des meilleurs montres de chez Dior à moins de 200€',
        content: 'Si vous voulez vous démarquez du lot avec des montres de luxe et tout ça sans se ruiner, vous êtes au bon endroit. Ici nous vous présentons la liste des meilleurs montre Dior à moins de 200€',
        like: '99k',
        view: '102k',
        date: '10 juin 2024',
        profile: './img/profile.jpeg',
        autor: 'TOSSOU Charbelle'
    },
    {
        link: 'liste-des-meilleurs-montres-de-chez-dior-a-moins-de-200€',
        image: './img/montre.jpeg',
        categorie: 'Montre de luxe',
        title: 'Liste des meilleurs montres de chez Dior à moins de 200€',
        content: 'Si vous voulez vous démarquez du lot avec des montres de luxe et tout ça sans se ruiner, vous êtes au bon endroit. Ici nous vous présentons la liste des meilleurs montre Dior à moins de 200€',
        like: '99k',
        view: '102k',
        date: '10 juin 2024',
        profile: './img/profile.jpeg',
        autor: 'TOSSOU Charbelle'
    },
])

</script>

<style lang="css" scoped>
#unique::after {
    content: '';
    width: 70px;
    height: 70px;
    border-radius: 17% 55% 0% 17% / 15% 53% 68% 10% ;
    background-color: #F2F4F8;
    position: absolute;
    top: -30px;
    right: 10px;
    z-index: 1;
}
#unique::before {
    content: '';
    width: 70px;
    height: 70px;
    border-radius: 55% 17% 14% 0% / 77% 15% 19% 78% ;
    background-color: #F2F4F8;
    position: absolute;
    bottom: -30px;
    left: 10px;
    z-index: 1;
}
@media screen and (max-width: 1023px) {
    #uniqueMobile::after {
        content: '';
        width: 50px;
        height: 50px;
        border-radius: 17% 55% 0% 17% / 15% 53% 68% 10% ;
        background-color: #F2F4F8;
        position: absolute;
        top: -10px;
        right: 10px;
        z-index: 1;
    }
    #uniqueMobile::before {
        content: '';
        width: 50px;
        height: 50px;
        border-radius: 55% 17% 14% 0% / 77% 15% 19% 78% ;
        background-color: #F2F4F8;
        position: absolute;
        bottom: -10px;
        left: 10px;
        z-index: 1;
    }
}
#citation {
    background: url("../assets/img/fond_gandhi.png") center center no-repeat;
    background-size: cover;
}

#forme {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}
#forme1 {
    border-radius: 45% 75% 85% 45% / 55% 55% 85% 85%;
}

</style>